export interface InboundRules {
  type: string;
  name: string; // Modified field name
  trunk: string; // Modified field name
  did: string; // Modified field name
  routing_office_hours: string; // Modified field name
  routing_outside_office_hours: string; // Modified field name
}

export const inboundRulesTableData: InboundRules[] = [
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },
  {
    type: "Actif",
    name: "Unyc",
    trunk: "pbx.eqinoxe.c...", // Example value changed to match the modified field
    did: "0556246219", // Example value changed to match the modified field
    routing_office_hours: "10 Bureau", // Example value changed to match the modified field
    routing_outside_office_hours: "10 Bureau" // Example value changed to match the modified field
  },



];
