import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Button,
  Card,
  Dropdown,
  ProgressBar,
  Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';

import { ColumnDef } from '@tanstack/react-table';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import Badge from 'components/base/Badge';
import EditorUsersAdvanceTable from 'components/base/EditorUsersAdvanceTable';
import { Ticket } from '../../../../hooks/dolibarr/useTicketsData';

const isRTL = '';

interface TicketsCellProps {
  name: string;
}
const TicketsCell: React.FC<TicketsCellProps> = ({ name }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <Link
        to={``}
        className="text-decoration-none fw-bold fs-8"
        onClick={handleShowModal}
      >
        {name}
      </Link>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Project Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Nom du projet</Form.Label>
              <Form.Control type="text" placeholder="Enter username" />
            </Form.Group>

            <Form.Group controlId="formBasicAssignee">
              <Form.Label>Assigné à</Form.Label>
              <Form.Control as="select">
                <option>Alex</option>
                <option>Florian</option>
                <option>Justin</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formBasicStartDate">
              <Form.Label>Date de début</Form.Label>
              <Form.Control type="date" />
            </Form.Group>

            <Form.Group controlId="formBasicEndDate">
              <Form.Label>Date de fin</Form.Label>
              <Form.Control type="date" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleCloseModal}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const TicketsTableColumns: ColumnDef<Ticket>[] = [
  {
    accessorKey: 'subject',
    header: 'Sujet',
    cell: ({ row }) => (
      <Link to={`/portail-revendeur/vos-tickets/${row.original.id}`}>
        {row.original.subject}
      </Link>
    ),
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
  {
    accessorKey: 'severity',
    header: 'Sévérité',
  },
  {
    accessorKey: 'author',
    header: 'Auteur',
  },
  {
    accessorKey: 'company',
    header: 'Tiers',
  },
  {
    accessorKey: 'creationDate',
    header: 'Date création',
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return new Date(value).toLocaleDateString();
    },
  },
  {
    accessorKey: 'status.label',
    header: 'État',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge variant="phoenix" bg="success">
          {status.label}
        </Badge>
      );
    },
  },
  {
    id: 'assigness',
    header: 'Assignés',
    cell: ({ row: { original } }) => {
      const { assigness } = original;
      const [showModal, setShowModal] = useState(false);

      const handleShowModal = () => setShowModal(true);
      const handleCloseModal = () => setShowModal(false);

      // Ensure assigness is defined and an array
      const safeAssigness = Array.isArray(assigness) ? assigness : [];

      return (
        <div className="d-flex align-items-center">
          {safeAssigness.length > 0 ? (
            <Avatar.Group total={safeAssigness.length} size="s">
              {safeAssigness.map((assigne) => (
                assigne && (
                  <Avatar
                    key={assigne.id}
                    src={assigne.avatar ? assigne.avatar : undefined}
                    variant={assigne.avatar ? 'image' : 'name'}
                    size="s"
                  >
                    {!assigne.avatar && assigne.name[0]}
                  </Avatar>
                )
              ))}
            </Avatar.Group>
          ) : (
            <>
              <Button variant="link" onClick={handleShowModal} className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                Assigner
              </Button>
              <Dropdown align={isRTL ? 'end' : 'start'} show={showModal} onToggle={() => setShowModal(!showModal)}>
                <Dropdown.Menu className="shadow-sm" style={{ minWidth: '20rem' }}>
                  <Card className="position-relative border-0">
                    <Card.Body className="p-0">
                      <div className="mx-3">
                        <h4 className="mb-3 fw-bold">Sélection du contact</h4>
                        <Form.Select className="mb-3">
                          <option value="">Sélectionner</option>
                          {safeAssigness.map((assigne) => (
                            <option key={assigne.id} value={assigne.id}>
                              {assigne.name}
                            </option>
                          ))}
                        </Form.Select>
                        <div className="text-end">
                          <Button variant="link" className="text-danger" onClick={handleCloseModal}>
                            Annuler
                          </Button>
                          <Button size="sm" variant="primary" className="px-5" onClick={handleCloseModal}>
                            Sauvegarder
                          </Button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </>
          )}
        </div>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' },
    },
  },

  {
    accessorKey: 'progress',
    header: 'Progression',
    cell: ({ row: { original } }) => {
      const { progress } = original;

      return progress ? (
        <>
          <p className="text-800 fs-10 mb-0">
            {progress.min} / {progress.max}
          </p>
          <ProgressBar
            now={(progress.min / progress.max) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      ) : null;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '5%' }, className: 'ps-3' },
    },
  },
  {
    id: 'action',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' },
    },
  },
];

const TicketsTable = () => {
  return (
    <div className="border-bottom">
      <EditorUsersAdvanceTable
        tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default TicketsTable;
