import { useState, useEffect } from 'react';
import axios from 'axios';
import { RetailerClientDataType } from 'data/iper-x/retailer/retailerClientsTableData';

// Données par défaut avec la propriété 'status' ajoutée à 'type'
const defaultTableData: RetailerClientDataType[] = [
  {
    id: 1,
    company: {
      name: 'Société Par Défaut',
      status: { label: 'Actif', type: 'success' }
    },
    product: { name: 'Produit Démo' },
    responsible: { name: 'Responsable Démo', functionName: 'Fonction Démo' },
    licence: 'XXXX-XXXX-XXXX-XXXX',
    type: { name: 'Hébergé', status: 'active' },
    date: '2023-01-01',
    expiration: { date: '2025-01-01', status: 'Valide' }
  }
];

const useRetailerClients = () => {
  const [tableData, setTableData] = useState<RetailerClientDataType[]>(defaultTableData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/Company/{companyId}/licences', {
        headers: {
          // Ajoutez ici les headers nécessaires
        }
      });
      setTableData(response.data.licences);
      setError(null); // Réinitialiser l'erreur si succès
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données. Affichage des données par défaut.');
      setTableData(defaultTableData); // Utiliser les données par défaut en cas d'erreur
    } finally {
      setLoading(false); // Finir le chargement dans tous les cas
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    tableData,
    setTableData,
    fetchData,
    defaultTableData,
    loading,
    error
  };
};

export default useRetailerClients;
