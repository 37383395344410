import React, { useState, ChangeEvent } from "react";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faFileImport, faFilter, faPlus } from "@fortawesome/free-solid-svg-icons";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { outboundRulesTableColumns } from "../../../../components/tables/ipbx/OutboundRulesTable";
import { outboundRulesTableData } from "../../../../data/iper-x/ipbx/outboundRules";
import VoicemailTable from "../../../../components/tables/ipbx/VoicemailTable";

const OutboundRulesList = () => {
  const table = useAdvanceTable({
    data: outboundRulesTableData,
    columns: outboundRulesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddRuleClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Règles sortantes</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleAddRuleClick}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter une règle sortante
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
              >
                <FontAwesomeIcon icon={faFilter} transform="down-3" className="text-primary" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <VoicemailTable />
        </div>
      </AdvanceTableProvider>

      <Modal show={openAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une règle sortante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
    <Form.Group controlId="ruleName">
      <Form.Label>Nom de la règle</Form.Label>
      <Form.Control type="text" name="ruleName" required />
    </Form.Group>

    <Form.Group controlId="externalCalls">
      <Form.Label>Appels de l’ext</Form.Label>
      <Form.Control type="text" name="externalCalls" required />
    </Form.Group>

    <Form.Group controlId="prefix">
      <Form.Label>Préfixe</Form.Label>
      <Form.Control type="text" name="prefix" required />
    </Form.Group>

    <Form.Group controlId="length">
      <Form.Label>Longueur</Form.Label>
      <Form.Control type="text" name="length" required />
    </Form.Group>

    <Form.Group controlId="extensionGroup">
      <Form.Label>Groupe d’extension</Form.Label>
      <Form.Control type="text" name="extensionGroup" required />
    </Form.Group>

    <Form.Group controlId="route1">
      <Form.Label>Route 1</Form.Label>
      <Form.Control type="text" name="route1" required />
    </Form.Group>

    <Form.Group controlId="route2">
      <Form.Label>Route 2</Form.Label>
      <Form.Control type="text" name="route2" required />
    </Form.Group>

    <Form.Group controlId="route3">
      <Form.Label>Route 3</Form.Label>
      <Form.Control type="text" name="route3" required />
    </Form.Group>

    <Form.Group controlId="route4">
      <Form.Label>Route 4</Form.Label>
      <Form.Control type="text" name="route4" required />
    </Form.Group>

    <Form.Group controlId="route5">
      <Form.Label>Route 5</Form.Label>
      <Form.Control type="text" name="route5" required />
    </Form.Group>

    <Button variant="primary" type="submit" className='mt-4'>
      Ajouter
    </Button>
  </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OutboundRulesList;
