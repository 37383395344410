import team32 from "assets/img/team/32.webp";
import { Status } from "components/base/Avatar";
import { BadgeBg } from "components/base/Badge";
import commerciale from "assets/img/iper-x/certification/commerciale.png"
import technique from "assets/img/iper-x/certification/technique.png"
import windows from "assets/img/logos/icons8-windows-client-24.png"
import mac from "assets/img/logos/icons8-mac-os-24.png"
import android from "assets/img/logos/icons8-android-os-24.png"


export interface UsersInRetailer {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
 
    status: Status;
    placeholder?: boolean;
    certification: string;
  };
  rôle: string;
  fonction: string;
  email: string;
  mobile: string;
  fixe: string;
  devices: number;
  sync_time: string;

}

export const usersTableDataRetailer: UsersInRetailer[] = [

  {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
            certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,

    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  }
];


export interface Users {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
    status: Status;
    placeholder?: boolean;
    password: string;
  };
  phone: {
    app: string;
    softphone: string;
  };
 
  email: string;
  mobile: string;
  calling_id: string;
  devices: string;
  sync_time: string;
  webrtc: boolean;


}

export const usersTableData: Users[] = [
  {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "online",
      password: 'rootdzdzdzdzd'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    webrtc: true,
    mobile: "064890201",
    calling_id: "0556246219",
    devices: "W70B",
    sync_time: "34 minute"
  },
  {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },
  {
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: false,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },{
    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      password: 'root'
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    phone: {
      app: windows,
      softphone: android,
    },
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    webrtc: true,
    calling_id: "0556246219",
    devices: "W56H",
    sync_time: "34 minute"
  },
];
