import React, { useState } from 'react';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { ChangeEvent } from "react";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { members } from "data/iper-x/editor/editorRetailerMembersInfo";
import EditorRetailerMembers, { EditorRetailerMemberColumn } from "components/tables/iper-x/editor/EditorRetailerMembers";

const EditorRetailer = () => {
  const table = useAdvanceTable({
    data: members,
    columns: EditorRetailerMemberColumn,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [showAddRetailerModal, setShowAddRetailerModal] = useState(false);

  const [newRetailer, setNewRetailer] = useState({
    COMPAGNIE: '',
    EMAIL: '',
    'NUMÉRO DE TÉLÉPHONE': '',
    VILLE: '',
    'SIRET': ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewRetailer(prevRetailer => ({
      ...prevRetailer,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    // Une fois soumis, vous pouvez réinitialiser les valeurs du formulaire
    setNewRetailer({
      COMPAGNIE: '',
      EMAIL: '',
      'NUMÉRO DE TÉLÉPHONE': '',
      VILLE: '',
      'SIRET': ''
    });
    setShowAddRetailerModal(false); // Fermer la modal après soumission
  };

  return (
    <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
      <div>
        <div className="mb-1">
          <AdvanceTableProvider {...table}>
            <h2 className="mb-3 mt-3">Revendeur</h2>
            <div className="mb-4">
              <Row className="g-3">
                <Col xs="auto">
                  <SearchBox
                    placeholder="Rechercher par nom"
                    onChange={handleSearchInputChange}
                  />
                </Col>
                <Col
                  xs="auto"
                  className="scrollbar overflow-hidden-y flex-grow-1"
                ></Col>
                <Col xs="auto">
                  <Button variant="link" className="text-900 me-4 px-0">
                    <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                    Export
                  </Button>
                  <Button variant="primary" onClick={() => setShowAddRetailerModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Nouveau revendeur
                  </Button>
                </Col>
              </Row>
            </div>
            <EditorRetailerMembers />
          </AdvanceTableProvider>
        </div>
      </div>

      {/* Modal pour ajouter un nouveau revendeur */}
      <Modal show={showAddRetailerModal} onHide={() => setShowAddRetailerModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Nouveau revendeur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} style={{ width: '100%' }}>

            <Form.Group controlId="COMPAGNIE">
              <Form.Label>COMPAGNIE</Form.Label>
              <Form.Control type="text" name="COMPAGNIE" value={newRetailer.COMPAGNIE} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="EMAIL">
              <Form.Label>EMAIL</Form.Label>
              <Form.Control type="email" name="EMAIL" value={newRetailer.EMAIL} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="NUMÉRO DE TÉLÉPHONE">
              <Form.Label>NUMÉRO DE TÉLÉPHONE</Form.Label>
              <Form.Control type="tel" name="NUMÉRO DE TÉLÉPHONE" value={newRetailer['NUMÉRO DE TÉLÉPHONE']} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="VILLE">
              <Form.Label>VILLE</Form.Label>
              <Form.Control type="text" name="VILLE" value={newRetailer.VILLE} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="SIRET">
              <Form.Label>SIRET</Form.Label>
              <Form.Control type="text" name="SIRET" value={newRetailer['SIRET']} onChange={handleChange} required />
            </Form.Group>

            <Button variant="primary" type="submit" className='mt-4 mb-4'>
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditorRetailer;
