import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import Avatar from 'components/base/Avatar';

interface UsersTableInRetailerProps {
  data: Array<{
    idUsers: number;
    name: string;
    lastname: string;
    mail: string;
    phone: string;
    mobile: string;
    idUsersRoles: number;
    idCompanies: number;
  }>;
}

export const usersTableColumns: ColumnDef<UsersTableInRetailerProps['data'][0]>[] = [

  {
    id: 'user',
    accessorKey: 'idUsers', // Assume idUsers is a unique identifier
    header: 'Nom d’utilisateur',
    cell: ({ row: { original } }) => {
      const { idUsers, name, lastname } = original; // Assurez-vous que ces champs existent
      return (
        <Link
          to={`/portail-revendeur/utilisateurs/details/${idUsers}`}
          className="d-flex align-items-center"
        >
          <Avatar src="" size="m" className="me-3" /> {/* Ajoutez un chemin correct pour l'avatar */}
          <p className="mb-0 text-1100 fw-bold">{name} {lastname}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '16%' },
      },
      cellProps: { className: 'py-0 white-space-nowrap' },
    },
  },
  {
    accessorKey: 'idUsersRoles',
    header: 'Rôle',
    cell: info => info.getValue(),
  },
  // {
  //   accessorKey: 'idUsers',
  //   header: 'ID Utilisateur',
  //   cell: info => info.getValue(),
  // },
  // {
  //   accessorKey: 'name',
  //   header: 'Nom',
  //   cell: info => info.getValue(),
  // },
  // {
  //   accessorKey: 'lastname',
  //   header: 'Prénom',
  //   cell: info => info.getValue(),
  // },
  {
    accessorKey: 'mail',
    header: 'Email',
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'phone',
    header: 'Téléphone',
    cell: info => info.getValue(),
  },
  {
    accessorKey: 'mobile',
    header: 'Mobile',
    cell: info => info.getValue(),
  },

  // {
  //   accessorKey: 'idCompanies',
  //   header: 'ID Entreprise',
  //   cell: info => info.getValue(),
  // },
];

const UsersTableInRetailer: React.FC<UsersTableInRetailerProps> = ({ data }) => {
  return (
    <div>
      <AdvanceTable
        tableProps={{
          // columns: usersTableColumns,
          // data: data,
          // className: 'phoenix-table fs-9'
        }}
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UsersTableInRetailer;
