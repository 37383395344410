import team32 from "assets/img/team/32.webp";
// import { BadgeBg } from 'components/base/Badge';
import { PageBreadcrumbItem } from "components/common/PageBreadcrumb";

export const editorRetailerMembersInfo: PageBreadcrumbItem[] = [
  {
    label: "Pages",
    url: "#!"
  },
  {
    label: "Members",
    active: true
  }
];

export type Member = {
  avatar?: string;
  name: string;
  email: string;
  city: string;
  mobile: number | string;
  stage: {
    label: string;
    value: number;
  };
  revenue: string;
  joined: string;
};

export const members: Member[] = [
  {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }, {
    avatar: team32,
    name: "Carry Anna",
    email: "annac34@gmail.com",
    city: "Budapest",
    mobile: "06 53 42 54 10",
    revenue: "23 000€",
    stage: {
      label: "Statement",
      value: 40
    },
    joined: "Dec 12, 12:56 PM"
  }

];
