import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import classNames from 'classnames';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { EditorMapClientCallTableDataType, EditorMapClientCallTableData } from 'data/iper-x/editor/editorMapRetailerData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React from 'react';
import { Link } from 'react-router-dom';

const columns: ColumnDef<EditorMapClientCallTableDataType>[] = [
  {
    accessorKey: 'user.name',
    header: 'Client final',
    cell: ({ row: { original } }) => {
      const {
        user: {name}
      } = original;
      return (
        <Link
          to="/ipbx/dashboard"
          className="d-flex align-items-center text-1000"
        >
          <h6 className="mb-0 text-1000 fw-bold">{name}</h6>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '45%', minWidth: '100px' },
        className: 'pe-3 ps-0'
      },
      cellProps: { className: 'py-2 ps-0', style: { whiteSpace: 'normal' } } 
    }
  },
  {
    accessorKey: 'produit',
    header: 'Produit',
    cell: ({ row: { original } }) => original.description,
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: '40px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-700 py-2 pe-2',
        style: { whiteSpace: 'normal' } 
      }
    }
  },
  {
    accessorKey: 'type',
    header: 'Type',
    cell: ({ row: { original } }) => original.type,
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: '115px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'white-space-nowrap text-900 text-start',
        style: { whiteSpace: 'normal' } 
      }
    }
  },
  {
    accessorKey: 'expirationDate',
    header: 'Expiration',
    cell: ({ row: { original } }) => original.expirationDate,
    meta: {
      headerProps: {
        style: { width: '10%', minWidth: '150px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold text-1000',
        style: { whiteSpace: 'normal' } 
      }
    }
  },
  {
    accessorKey: 'administrer',
    header: 'Administrer',
    cell: ({ row: { original } }) => {
      return (
        <div className="d-flex align-items-center flex-1">
          <FontAwesomeIcon
            icon={faClock}
            className={classNames('me-1 text-500', {
              'text-success': original.administrer === 'Active'
            })}
            transform="shrink-2 up-1"
          />
          <span className="fw-bold fs-9 text-900">{original.administrer}</span>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: '2%', minWidth: '10px' },
        className: 'ps-0 text-end'
      },
      cellProps: {
        className: 'white-space-nowrap fw-semi-bold text-1000',
        style: { whiteSpace: 'normal' } 
      }
    }
  },
  {
    id: 'dealDropdown',
    accessorKey: '',
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'pe-0 py-2', style: { whiteSpace: 'normal' } } 
    }
  }
];

const EditorMapClients = () => {
  const table = useAdvanceTable({
    data: EditorMapClientCallTableData,
    columns,
    pageSize: 5,
    pagination: true,
    sortable: true,
  });

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="border-y">
          <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default EditorMapClients;
