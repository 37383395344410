
import SearchBox from 'components/common/SearchBox';
import FinalClientTable, { FinalClientcolumns } from 'components/tables/old/FinalClientTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { ChangeEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { finalClientTableData } from 'data/crm/leadsData';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const FinalClientInDetailRetailer = () => {

  const table = useAdvanceTable({
    data: finalClientTableData,
    columns: FinalClientcolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <AdvanceTableProvider {...table}>
    <div>
     
        <h2 className="mb-0">Détail des clients finaux</h2>
        <Row className="g-3 justify-content-between mb-4 mt-4">         
          <Col xs="auto">
            <div className="d-flex">
              <SearchBox
                placeholder="Rechercher par nom"
                className="me-2"
                onChange={handleSearchInputChange}
              />

            </div>
          </Col>
        </Row>
     
      <FinalClientTable />
    </div>
    </AdvanceTableProvider>
  );
};

export default FinalClientInDetailRetailer;
