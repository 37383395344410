import React, { useState } from 'react';
import { Button, Col, Modal, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileImport, faFilter, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UsersTable, { usersTableColumns } from 'components/tables/ipbx/UsersTable';
import { usersTableData } from '../../../data/iper-x/ipbx/users';
import LeadsFilterModal from '../../../components/modals/LeadsFilterModal';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';

const UsersList = () => {
  const table = useAdvanceTable({
    data: usersTableData,
    columns: usersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false); // State for password requirements modal

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [user, setUser] = useState({
    username: '',
    phoneNumber: '',
    email: '',
    mobile: '',
    fixed: '',
    telephone: '',
    password: '' // Added password field to user state
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    handleCloseModal();
  };

  const handleSearchInputChange = (e) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const togglePasswordModal = () => {
    setShowPasswordModal(!showPasswordModal);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Utilisateurs</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300" placeholder="Rechercher" onChange={handleSearchInputChange} />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleShowModal}>
                Ajouter un utilisateur
              </Button>
              <Button variant="phoenix-secondary" className="px-3 ms-2" onClick={() => setOpenFilterModal(true)}>
                <FontAwesomeIcon icon={faFilter} transform="down-3" className="text-primary" />
              </Button>
              <LeadsFilterModal show={openFilterModal} handleClose={() => setOpenFilterModal(false)} />
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <UsersTable />
        </div>
      </AdvanceTableProvider>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control type="text" name="username" value={user.username} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Mot de passe</Form.Label>
              <FontAwesomeIcon icon={faQuestionCircle} className="ms-2 fs-xs" onClick={togglePasswordModal} style={{ cursor: 'pointer' }} />
              <Form.Control type="password" name="password" value={user.password} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="phoneNumber">
              <Form.Label>Numéro de poste</Form.Label>
              <Form.Control type="text" name="phoneNumber" value={user.phoneNumber} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={user.email} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="text" name="mobile" value={user.mobile} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="fixed">
              <Form.Label>Téléphone fixe</Form.Label>
              <Form.Control type="text" name="fixed" value={user.fixed} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="telephone">
              <Form.Label>Téléphone</Form.Label>
              <Form.Control type="text" name="telephone" value={user.telephone} onChange={handleChange} required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4">
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Password Requirements Modal */}
      <Modal show={showPasswordModal} onHide={togglePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Exigences du mot de passe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Le mot de passe doit contenir :</p>
          <ul>
            <li>Au moins 8 caractères</li>
            <li>Au moins une lettre majuscule et une minuscule</li>
            <li>Au moins un chiffre</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={togglePasswordModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UsersList;
