import React, { useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useMainLayoutContext } from "providers/MainLayoutProvider";
import ContactsCreatedChart from "../../../components/charts/e-charts/ContactsCreatedChart";
import { stats } from "data/iper-x/ipbx/ipbxDetailsInfo";
import AdClicksChart from "../../../components/charts/e-charts/AdClicksChart";
import IpbxPrintingDimensionsCard from "../../../components/cards/ipbx/IpbxPrintingDimensionsCard";
import classNames from "classnames";
import InfoList from "../../../components/modules/ipbx/info-list/InfoList";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";


const DashboardIpbx = () => {

  const { setFooterClass } = useMainLayoutContext();

  useEffect(() => {
    setFooterClass('d-none d-lg-block');
    return () => {
      setFooterClass('');
    };
  }, []);

  return (
    <>

      <Col md={9} xs="auto">
        {/*<PageBreadcrumb items={ipbxBreadcrumbItems} />*/}
        {/*<h2 className="mb-4 text-1100">Nom de l’utilisateur</h2>*/}

        {/*<div className="ms-3">*/}
        {/*  <h4 className="mb-0">f  </h4>*/}
        {/*  <p className="text-800 fs-9 mb-0"> ff</p>*/}
        {/*</div>*/}
        <h3 className="fw-bolder mb-2">Pigma Communications</h3>
        <p className="mb-0">
          <Link to="#!" className="fw-bold">👤 Anthoney Michae </Link> PDG 12/04/2024,
          <Link to="#!" className=""> ansolo5@jeemail.com</Link>
          <Link to="#!"><FontAwesomeIcon icon={faPencil} className="fs-10 text-500 ms-3" /></Link></p>

      </Col>

      <div className="mb-9" data-bs-spy="scroll">

        <div className="mt-3 mx-lg-n4">
          <IpbxPrintingDimensionsCard stats={stats} className="mb-9" />

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut système</h3>
                    <div className="mt-5">
                      <AdClicksChart style={{ height: 270, width: "100%" }} />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">

                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Usage du disque</h4>
                            <p className="fw-bold mb-1">15% utiliser
                              (49.8 GB restant)</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Mémoire</h4>
                            <p className="fw-bold mb-1">50% utiliser 979.7 MB restant</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5 mx-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">CPU</h4>
                            <p className="fw-bold mb-1">2/100</p>
                          </div>
                        </div>
                      </Col>

                    </Row>
                  </Card.Body>
                </Card>

              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Trafic aujourd'hui</h3>
                    <div className="mt-5 mb-5">
                      <ContactsCreatedChart style={{ height: 270, width: "100%" }} />
                    </div>
                    <Row className="g-4 g-xl-1 g-xxl-3 justify-content-between">

                      <Col key={1} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            " ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Appel entrant</h4>
                            <p className="fw-bold mb-1">1</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={2} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Appel sortant</h4>
                            <p className="fw-bold mb-1">9 inscrits/ 21 non inscrits </p>
                          </div>
                        </div>
                      </Col>
                      <Col key={3} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Interne</h4>
                            <p className="fw-bold mb-1">12</p>
                          </div>
                        </div>
                      </Col>
                      <Col key={4} sm="auto">
                        <div
                          className={classNames(
                            "d-sm-block d-inline-flex d-md-flex flex-xl-column flex-xxl-row align-items-center align-items-xl-start align-items-xxl-center",
                            "border-start-sm ps-sm-5"
                          )}
                        >

                          <div>
                            <h4 className="fw-bolder text-nowrap">Transite</h4>
                            <p className="fw-bold mb-1">32</p>
                          </div>
                        </div>
                      </Col>


                    </Row>
                  </Card.Body>
                </Card>

              </Col>
            </Row>
          </div>

          <div className="mb-9">
            <Row className="g-3 mt-n7">
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Statut PBX</h3>

                    <InfoList data="status" />
                  </Card.Body>
                </Card>

              </Col>
              <Col xs={12} xl={6}>
                <Card className="h-100">
                  <Card.Body>
                    <h3>Informations</h3>

                    <InfoList data="info" />
                  </Card.Body>
                </Card>

              </Col>
            </Row>
          </div>

        </div>

      </div>
    </>
  );
};

export default DashboardIpbx;