import React, { useState } from 'react';
import CoverUpload from 'components/common/CoverUpload';
import AvatarUpload from 'components/common/AvatarUpload';
import { Button, Card, Col, Form, Row, Modal } from "react-bootstrap";
import { CSSProperties } from "react";
import coverImage from 'assets/img/generic/59.png';

interface SocialProfileCardProps {
  showAbout?: boolean;
  avatar: string;
  className?: string;
}

const SocialProfileCard = ({
                             showAbout,
                             avatar,
                             className
                           }: SocialProfileCardProps) => {
  const [show, setShow] = useState(false); // Define the show state variable

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Card className={className}>
      <Card.Header
        className="position-relative mb-6"
        style={{ minHeight: '130px' }}
      >
        <CoverUpload
          src={coverImage}
          gradient={
            'linear-gradient(0deg, #000000 -3%, rgba(0, 0, 0, 0) 83%)' as CSSProperties
          }
        />
        <AvatarUpload
          size="4xl"
          src={avatar}
          status="gold"
          thumbnail
          className="feed-avatar-profile"
        />
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-wrap mb-2 align-items-center">
              <div className="d-flex align-items-center mb-3">
                <h3 className="fw-bolder mb-2">Pigma communications</h3>
                <Button variant="link" className="px-3" onClick={handleShow}>
                  Modifier
                </Button>
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Modification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="companyName">
                    <Form.Label>Nom de l'entreprise</Form.Label>
                    <Form.Control type="text" placeholder="Pigma communications" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="partnerID">
                    <Form.Label>ID partenaire</Form.Label>
                    <Form.Control type="text" placeholder="203523" />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="certificationLevel">
                    <Form.Label>Niveau de certification</Form.Label>
                    <Form.Control type="text" placeholder="Avancé" />
                  </Form.Group>
                  <div className="mb-3">
                    <Form.Label>Votre distributeur</Form.Label>
                    <Form.Control type="text" defaultValue="ipercom" readOnly />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Service Commercial</Form.Label>
                    <Form.Control type="text" defaultValue="+33 3 89 31 06 20" readOnly />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Email du Service Commercial</Form.Label>
                    <Form.Control type="email" defaultValue="commercial@ipercom.com" readOnly />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Votre représentant commercial</Form.Label>
                    <Form.Control type="text" defaultValue="Marco Crema" readOnly />
                  </div>
                  <div className="mb-3">
                    <Form.Label>Compte Manager</Form.Label>
                    <Form.Control type="text" defaultValue="commercial@ipercom.com" readOnly />
                  </div>
                </Form>

              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Annuler
                </Button>
                <Button variant="primary" onClick={handleClose}>
                  Sauvegarder
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>ID partenaire :</b> 203523
              </p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Niveau de certification :</b> Avancé
              </p>
            </div>
            <b>PDG . NOM PRENOM</b>
            <b>Siret</b>
            <b>Adresse</b>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre distributeur</b>
              </p>
              <p className="mb-0">ipercom</p>
              <p className="mb-0">Service Commercial</p>
              <p className="mb-0">+33 3 89 31 06 20</p>
              <p className="mb-0">commercial@ipercom.com</p>
            </div>
            <div className="mt-4">
              <p className="fw-semi-bold mb-0">
                <b>Votre représentant commercial</b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">France</p>
                <p className="mb-0">Marco Crema</p>
                <p className="mb-0">Compte Manager</p>
                <p className="mb-0">commercial@ipercom.com</p>
              </p>
              <p className="fw-semi-bold mb-0">
                <b>Condition de reglement </b>
              </p>
              <p className="text-700 mb-0">
                <p className="mb-0">Prelevement automatique</p>
              </p>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
);
};

export default SocialProfileCard;
