import React, { useState, ChangeEvent, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { faFileExport, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import DatePicker from 'react-flatpickr';
import RetailerLeadsTable, {
  retailerLeadsTableColumns
} from 'components/tables/iper-x/retailer/RetailerLeadsTable';

interface Lead {
  idLeads: number;
  name: string;
  ceoName: string;
  siret: string;
  address: string;
  mail: string;
  website: string;
  phone: string;
  numbersEmployees: number;
  annualIncome: number;
  nafCode: string;
  idSourcesLeads: number;
  idCompaniesClientsTypes: number;
  idCommonCities: number;
  devicesNumber: number;
  accessNumber: number;
  progressMin: number;
  progressMax: number;
  statusLabel: string;
  statusType: string;
  idUserAssignedRetailer: number;
}

const RetailerLeads: React.FC = () => {
  const [leadsData, setLeadsData] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await fetch(
          'https://rct-backend.ipercom.io/api/Lead',
          {
            method: 'GET',
            headers: {
              accept: 'application/json'
            }
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setLeadsData(data);
      } catch (error) {
        setError('Failed to fetch leads data. Please try again later.');
        console.error('Error fetching leads data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeads();
  }, []);

  const table = useAdvanceTable<Lead>({
    data: leadsData,
    columns: retailerLeadsTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });

  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <AdvanceTableProvider {...table}>
      <h2 className="mb-0">Mes leads</h2>
      <Row className="g-3 justify-content-between mb-4">
        <Col xs="auto">
          <Button variant="link" className="text-900 mt-2 px-0">
            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
            Exporter
          </Button>
        </Col>
        <Col xs="auto">
          <div className="d-flex">
            <SearchBox
              placeholder="Rechercher par nom"
              className="me-2"
              onChange={handleSearchInputChange}
            />
            <DatePicker defaultValue="Mar 1, 2022" />
            <Button
              variant="phoenix-secondary"
              className="px-3 ms-2"
              onClick={() => setOpenFilterModal(true)}
            >
              <FontAwesomeIcon
                icon={faFilter}
                transform="down-3"
                className="text-primary"
              />
            </Button>
          </div>
          <LeadsFilterModal
            show={openFilterModal}
            handleClose={() => setOpenFilterModal(false)}
          />
        </Col>
      </Row>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <RetailerLeadsTable />
      )}
    </AdvanceTableProvider>
  );
};

export default RetailerLeads;
