import { Button, Col, Dropdown, Row } from "react-bootstrap";
import PageBreadcrumb, { PageBreadcrumbItem } from "components/common/PageBreadcrumb";
import { editorUserRightsBreadcrumbItems } from "data/breadcrumbItem";
import { useParams } from "react-router-dom";
import { faEdit, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AboutLeadCard from "components/cards/AboutLeadCard";
import LeadAddressCard from "components/cards/LeadAddressCard";
import RetailerLeadProfileCard from "components/cards/iperx/RetailerLeadProfileCard";
import LeadDetailsOffcanvas from "components/modules/crm/LeadDetailsOffcanvas";
import UserDetailsInfo from "components/modules/ipbx/users-details/UserDetailsInfo";
import { dealDetailsInfoData } from "data/crm/dealDetailsInfo";

import { useState } from "react";

const DetailsLeadDashboardInEditor = () => {
  const { name } = useParams();

  const [openOffcanvas, setOpenOffcanvas] = useState(false);

   const leadsBreadcrumbItems: PageBreadcrumbItem[] = [
    {
      label: 'Portail éditeur',
      url: '/portail-revendeur'
    },
    {
      label: 'Détail prospect',
      active: true
    }
  ];

  const updatedBreadcrumbItems = editorUserRightsBreadcrumbItems.map((item, index) => {
    if (index === editorUserRightsBreadcrumbItems.length - 1) {
      return {
        ...item,
        label: `Détails leads`
      };
    }
    return item;
  });

  return (
    <div>
      <PageBreadcrumb items={leadsBreadcrumbItems} />
      <div className="pb-9">
        <Row className="align-items-center justify-content-between g-3 mb-3">
          <Col xs={12} md="auto">
            <h2 className="mb-0">Détail prospect</h2>
          </Col>
          <Col xs={12} md="auto">
            <div className="d-flex gap-2">
              <Button variant="phoenix-secondary" className="px-3 px-sm-5">
                <FontAwesomeIcon icon={faEdit} className="me-0 me-sm-2" />
                <span className="d-none d-sm-inline">Modifier</span>
              </Button>


            </div>
          </Col>
        </Row>

        <Row className="g-0 g-md-4 g-xl-6">
          <Col md={5} xl={4} className="d-none d-md-block">
            <div className="sticky-leads-sidebar">
              <div className="lead-details-offcanvas bg-soft scrollbar">
                <div className="d-flex justify-content-between align-items-center mb-2 d-md-none">
                  <h3 className="mb-0">Détails du lead</h3>
                </div>
                <RetailerLeadProfileCard className="mb-3" />

                <LeadAddressCard />
              </div>
            </div>
          </Col>
          <Col md={7} xl={8}>
            <div className="lead-details-container">

              <UserDetailsInfo data={dealDetailsInfoData} className="mb-7" />
              <AboutLeadCard className="mb-3" />
            </div>
          </Col>
        </Row>
      </div>
      <LeadDetailsOffcanvas open={openOffcanvas} setOpen={setOpenOffcanvas} />
    </div>
  );
};


export default DetailsLeadDashboardInEditor;
