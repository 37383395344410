import { useState, useEffect, useCallback } from 'react';

const defaultUsersData = [
  {
    idUsers: 1,
    name: 'Stephane',
    lastname: 'Degouve',
    avatar: 'https://ui-avatars.com/api/?name=Stephane+Degouve',
    mail: 'sdegouve@ipercom.com',
    phone: '1234',
    mobile: '1234',
    userRole: {
      id: 2,
      name: 'string',
      slug: 'string'
    },
    company: {
      id: 1,
      name: 'string',
      slug: 'string'
    }
  }
];

const useRetailerUsers = () => {
  const [usersData, setUsersData] = useState(defaultUsersData);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await fetch('https://rct-backend.ipercom.io/api/User', {
        method: 'GET',
        headers: {
          'accept': 'text/plain',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setUsersData(data);
      setError(null); // Réinitialiser l'erreur si succès
    } catch (error) {
      console.error("Erreur lors de la récupération des données de l'API", error);
      setError('Impossible de récupérer les données. Affichage des données par défaut.');
      setUsersData(defaultUsersData); // Utiliser les données par défaut en cas d'erreur
    } finally {
      setLoading(false); // Arrêter le chargement
    }
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return {
    usersData,
    loading,
    error
  };
};

export default useRetailerUsers;
