export interface Trunks {
  status: string;
  name: string;
  host: string;
  type: string;
  simultaneous_calls: number;
  main_trunk_number: string;
  sent_record: string;
  record_ok: string;
  last_failure: string;
}

export const trunksTableData: Trunks[] = [
  {
    status: "Actif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  },
  {
    status: "Inactif",
    name: "Unyc",
    host: "pbx.eqinoxe.c...",
    type: "Provider",
    simultaneous_calls: 19,
    main_trunk_number: "0556246219",
    sent_record: "11/01/2024 00:14:02",
    record_ok: "11/01/2024 00:14:18",
    last_failure: "11/01/2024 00:14:18"
  }


];
