import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";

import { InboundRules } from "../../../data/iper-x/ipbx/inboundRules";
import OutboundRulesList from "../../../pages/apps/ipbx/gestion-des-appels/OutboundRulesList";
import { OutboundRules } from "../../../data/iper-x/ipbx/outboundRules";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import ActionDropdownItems from "components/common/ActionDropdownItems";
import { useState } from "react";
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';

export const inboundRulesTableColumns: ColumnDef<InboundRules>[] = [
  {
    accessorKey: "type",
    header: "Type",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "trunk",
    header: "Trunk",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "did",
    header: "Numéro SDA/DID",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "routing_office_hours",
    header: "Routage heure de bureau",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "routing_outside_office_hours",
    header: "Hors bureau",
    meta: {
      headerProps: { style: { width: "18%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    cell: ({ row }) => {
      const [showForm, setShowForm] = useState(false);

      const toggleForm = () => {
        setShowForm(!showForm);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item eventKey="1" onClick={toggleForm}>
                Modifier
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
          {showForm && (
            <Modal show={showForm} onHide={toggleForm}>
              <Modal.Header closeButton>
                <Modal.Title>Modifier la règle entrante</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="type">
                    <Form.Label>TYPE</Form.Label>
                    <Form.Control type="text" name="type" required />
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label>NOM</Form.Label>
                    <Form.Control type="text" name="name" required />
                  </Form.Group>

                  <Form.Group controlId="trunk">
                    <Form.Label>TRUNK</Form.Label>
                    <Form.Control type="text" name="trunk" required />
                  </Form.Group>

                  <Form.Group controlId="sdaDidNumber">
                    <Form.Label>NUMÉRO SDA/DID</Form.Label>
                    <Form.Control type="text" name="sdaDidNumber" required />
                  </Form.Group>

                  <Form.Group controlId="officeHoursRouting">
                    <Form.Label>ROUTAGE HEURE DE BUREAU</Form.Label>
                    <Form.Control type="text" name="officeHoursRouting" required />
                  </Form.Group>

                  <Form.Group controlId="offHoursRouting">
                    <Form.Label>HORS BUREAU</Form.Label>
                    <Form.Control type="text" name="offHoursRouting" required />
                  </Form.Group>

                  <Button variant="primary" type="submit" className='mt-4'>
                    Ajouter
                  </Button>
                </Form>
              </Modal.Body>


            </Modal>
          )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '1%' } },
      cellProps: {
        className: 'text-center',
      },
    },
  },
];

const InboundRulesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default InboundRulesTable;
