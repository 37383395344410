// src/components/tables/iper-x/retailer/OrdersTable.tsx

import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { currencyFormat } from 'utils/currencyFormat';

export interface Order {
  id: number;
  ref: string;
  tiers: string;
  date_commande: number;
  date_livraison: string;
  total_ht: number;
  author: string;
  status: string;
}

export const ordersTableColumns: ColumnDef<Order>[] = [
  {
    accessorKey: 'ref',
    header: 'Réf.',
    cell: ({ row: { original } }) => (
      <OrderPreviewLink order={original} />
    ),
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'tiers',
    header: 'Tiers',
    meta: {
      headerProps: { style: { width: '25%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'date_commande',
    header: 'Date de commande',
    cell: ({ getValue }) => {
      const value = getValue<number>();
      return new Date(value * 1000).toLocaleDateString();
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'date_livraison',
    header: 'Date prévue de livraison',
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return value ? new Date(value).toLocaleDateString() : '';
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'total_ht',
    header: 'Montant HT',
    cell: ({ row: { original } }) => currencyFormat(original.total_ht),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end fw-semi-bold text-1000' }
    }
  },
  {
    accessorKey: 'author',
    header: 'Auteur',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'status',
    header: 'État',
    cell: ({ getValue }) => {
      const value = getValue<string>();
      return value === '0' ? 'Brouillon' : 'Autre';
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'ps-0' }
    }
  }
];

const OrderPreviewLink: React.FC<{ order: Order }> = ({ order }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Link to="#" onClick={handleShow} className="fw-semi-bold">
        {order.ref}
      </Link>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Preview for {order.ref}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="dialogforpopup" style={{ width: "auto", minHeight: "0px", maxHeight: "none", height: "706.012px" }} className="ui-dialog-content ui-widget-content">
            <embed
              src={`https://erp.ipercom.io/document.php?modulepart=commande&attachment=0&file=${order.ref}/${order.ref}.pdf&&entity=1`}
             type="application/pdf"
             width="100%"
             height="98%"/>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const OrdersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default OrdersTable;
