export interface CallGroups {
  extension: string; // Assuming 'Ext.' refers to an extension number or identifier
  name: string; // Nom
  type: string; // Type
  noResponseDestination: string; // Destination de non réponse
  // Other fields can be included as needed
}

export const callGroupsTableData: CallGroups[] = [
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
  {
    extension: "HOL", // Example value
    name: "Réunion", // Example value
    type: "Standard", // Example value
    noResponseDestination: "End Call", // Example value
    // Initialize other fields as necessary
  },
];
