// import React, { useState } from 'react';
// import {
//   Form,
//   Button,
//   Card,
//   Dropdown,
//   ProgressBar,
//   Modal
// } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCaretDown, faPlus } from '@fortawesome/free-solid-svg-icons';
//
// import { ColumnDef } from '@tanstack/react-table';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
// import Avatar from 'components/base/Avatar';
// import RevealDropdown, {
//   RevealDropdownTrigger
// } from 'components/base/RevealDropdown';
// import ActionDropdownItems from 'components/common/ActionDropdownItems';
// import Badge from 'components/base/Badge';
// import EditorUsersAdvanceTable from 'components/base/EditorUsersAdvanceTable';
// import { usersInEditorData } from 'data/project-management/usersInEditorData';
// import { Ticket } from "../../../../hooks/dolibarr/useTicketsData";
//
// const isRTL = '';
//
// interface TicketsCellProps {
//   name: string;
// }
// const TicketsCell: React.FC<TicketsCellProps> = ({ name }) => {
//   const [showModal, setShowModal] = useState(false);
//
//   const handleCloseModal = () => setShowModal(false);
//   const handleShowModal = () => setShowModal(true);
//   const handleSubmit = () => {
//     // e.preventDefault();
//     // Logique de soumission du formulaire ici
//     handleCloseModal();
//   };
//
//   return (
//     <>
//       <Link
//         to={``}
//         className="text-decoration-none fw-bold fs-8"
//         onClick={handleShowModal}
//       >
//         {name}
//       </Link>
//       <Modal show={showModal} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>Project Details</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             <Form.Group controlId="formBasicUsername">
//               <Form.Label>Nom du projet</Form.Label>
//               <Form.Control type="text" placeholder="Enter username" />
//             </Form.Group>
//
//             <Form.Group controlId="formBasicAssignee">
//               <Form.Label>Assigné à</Form.Label>
//               <Form.Control as="select">
//                 <option>Alex</option>
//                 <option>Florian</option>
//                 <option>Justin</option>
//               </Form.Control>
//             </Form.Group>
//
//             <Form.Group controlId="formBasicStartDate">
//               <Form.Label>Date de début</Form.Label>
//               <Form.Control type="date" />
//             </Form.Group>
//
//             <Form.Group controlId="formBasicEndDate">
//               <Form.Label>Date de fin</Form.Label>
//               <Form.Control type="date" />
//             </Form.Group>
//           </Form>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//           <Button variant="primary" onClick={handleCloseModal}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };
//
// export const EditorUsersTableColumns: ColumnDef<Ticket>[] = [
//   {
//     accessorKey: 'name',
//     header: 'Nom du projet',
//     cell: ({ row: { original } }) => {
//       const { name } = original;
//       return <TicketsCell name={name} />;
//     },
//     meta: {
//       cellProps: { className: 'white-space-nowrap py-4' },
//       headerProps: { style: { width: '30%' } }
//     }
//   },
//   {
//     id: 'assigness',
//     header: 'Assignés',
//     cell: ({ row: { original } }) => {
//       const { assigness } = original;
//
//       return (
//         <>
//           <div className="d-flex align-items-center">
//             {assigness.length > 0 && (
//               <Avatar.Group total={assigness.length} size="s">
//                 {assigness.map(assigne => (
//                   <Avatar
//                     key={assigne.id}
//                     src={assigne.avatar ? assigne.avatar : undefined}
//                     variant={assigne.avatar ? 'image' : 'name'}
//                     size="s"
//                   >
//                     {!assigne.avatar && assigne.name[0]}
//                   </Avatar>
//                 ))}
//               </Avatar.Group>
//             )}
//             <Dropdown align={isRTL ? 'end' : 'start'}>
//               <Dropdown.Toggle
//                 variant="link"
//                 className="text-800 text-decoration-none dropdown-caret-none p-0 fs-8 fw-normal"
//               >
//                 <div className="d-flex align-items-center">
//                   <span className="badge-phoenix badge-phoenix-primary badge">
//                     <FontAwesomeIcon
//                       icon={faPlus}
//                       className="primary dark__text-400 me-1"
//                       transform="grow-4 down-1"
//                     />
//                     Assigner
//                     <FontAwesomeIcon
//                       icon={faCaretDown}
//                       className="text-800 fs-9 ms-2"
//                     />
//                   </span>
//                 </div>
//               </Dropdown.Toggle>
//               <Dropdown.Menu
//                 className="shadow-sm"
//                 style={{ minWidth: '20rem' }}
//               >
//                 <Card className="position-relative border-0">
//                   <Card.Body className="p-0">
//                     <div className="mx-3">
//                       <h4 className="mb-3 fw-bold">Sélection du contact</h4>
//                       <Form.Select className="mb-3">
//                         <option value="">Sélectionner</option>
//                         {assigness.map(assigne => (
//                           <option key={assigne.id} value={assigne.id}>
//                             {assigne.name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                       <div className="text-end">
//                         <Button variant="link" className="text-danger">
//                           Annuler
//                         </Button>
//                         <Button size="sm" variant="primary" className="px-5">
//                           Sauvegarder
//                         </Button>
//                       </div>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </Dropdown.Menu>
//             </Dropdown>
//           </div>
//         </>
//       );
//     },
//     meta: {
//       cellProps: { className: 'ps-3 py-4' },
//       headerProps: { style: { width: '10%' }, className: 'ps-3' }
//     }
//   },
//   {
//     header: 'Date de début',
//     accessorKey: 'start',
//     meta: {
//       cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap py-4' },
//       headerProps: { style: { width: '10%' }, className: 'ps-3' }
//     }
//   },
//   {
//     header: 'Date limite',
//     accessorKey: 'deadline',
//     meta: {
//       cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap py-4' },
//       headerProps: { style: { width: '15%' }, className: 'ps-3' }
//     }
//   },
//   {
//     accessorKey: 'task',
//     header: 'Nr Ticket',
//     meta: {
//       cellProps: { className: 'ps-3 text-900 py-4' },
//       headerProps: { style: { width: '12%' }, className: 'ps-3' }
//     }
//   },
//   {
//     accessorKey: 'task',
//     header: 'Tâche',
//     meta: {
//       cellProps: { className: 'ps-3 text-900 py-4' },
//       headerProps: { style: { width: '12%' }, className: 'ps-3' }
//     }
//   },
//   {
//     id: 'progress',
//     header: 'Progression',
//     cell: ({ row: { original } }) => {
//       const { progress } = original;
//
//       return (
//         <>
//           <p className="text-800 fs-10 mb-0">
//             {progress.min} / {progress.max}
//           </p>
//           <ProgressBar
//             now={(progress.min / progress.max) * 100}
//             style={{ height: 3 }}
//             variant="success"
//           />
//         </>
//       );
//     },
//     meta: {
//       cellProps: { className: 'ps-3 py-4' },
//       headerProps: { style: { width: '5%' }, className: 'ps-3' }
//     }
//   },
//   {
//     id: 'status',
//     header: 'Statut',
//     accessorFn: ({ status }) => status.label,
//     cell: ({ row: { original } }) => {
//       const { status } = original;
//       return (
//         // bg={status.type}
//         <Badge variant="phoenix" bg="success">
//           {status.label}
//         </Badge>
//       );
//     },
//     meta: {
//       cellProps: { className: 'ps-8 py-4' },
//       headerProps: { style: { width: '10%' }, className: 'ps-8' }
//     }
//   },
//   {
//     id: 'action',
//     cell: () => (
//       <RevealDropdownTrigger>
//         <RevealDropdown>
//           <ActionDropdownItems />
//         </RevealDropdown>
//       </RevealDropdownTrigger>
//     ),
//     meta: {
//       headerProps: { style: { width: '10%' }, className: 'text-end' },
//       cellProps: { className: 'text-end' }
//     }
//   }
// ];
//
// const ProjectListTable = () => {
//   return (
//     <div className="border-bottom">
//       <EditorUsersAdvanceTable
//         tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
//       />
//       <AdvanceTableFooter pagination className="py-3" />
//     </div>
//   );
// };
//
// export default ProjectListTable;
