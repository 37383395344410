import Avatar from 'components/base/Avatar';
import { Card, Col, Dropdown, Form, ProgressBar, Row } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Button from '../../base/Button';
import React from 'react';
import FeatherIcon from "feather-icons-react";
import Badge from "../../base/Badge";

const isRTL = '';
const name = '';
const avatar = '';
const RetailerProfileCard = ({ className }: { className?: string }) => {
  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <Row className="align-items-center g-3 text-center text-xxl-start">
          <Col xs={12} sm="auto" className="flex-1">
            <h3 className="fw-bolder mb-2">GEKOM</h3>



            <Dropdown align={isRTL ? "end" : "start"}>
              <Dropdown.Toggle
                variant="link"
                className="text-800 text-decoration-none dropdown-caret-none p-0 fs-8 fw-normal"
              >

                <div className="d-flex align-items-center">
                  <p className="text-800 fw-bold">Chiffre d’affaires: 12 000 € </p>

                </div>
                <p className="text-800 ">
                  Choisir un revendeur
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    className="text-800 fs-9 ms-2"
                  />
                </p>
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="shadow-sm"
                style={{ minWidth: "20rem" }}
              >
                <Card className="position-relative border-0">
                  <Card.Body className="p-0">
                  <div className="mx-3">
                      <h4 className="mb-3 fw-bold">Selection du contact</h4>

                      <Form.Select className="mb-3">
                        <option value="">Select</option>
                        <option value="1">Jerry Seinfield</option>
                        <option value="2">Anthoney Michael</option>
                        <option value="3">Ansolo Lazinatov</option>
                      </Form.Select>
                      <div className="text-end">
                        <Button variant="link" className="text-danger">
                          Cancel
                        </Button>
                        <Button size="sm" variant="primary" className="px-5">
                          Save
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Dropdown.Menu>
            </Dropdown>


            <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-5 pt-3">
              <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                <Avatar size="xl" src={avatar} className="me-3  status-gold" />
                <div>
                  <h5>Milind Mikuja GEKOM</h5>
                  <p className="text-800">PDG, Siège Sociale</p>
                </div>
              </div>
              <div>
              <Badge variant="phoenix" bg="success" className="me-2">
                  Succès
                </Badge>
                <Badge variant="phoenix" bg="danger" className="me-2">
                  Perdu
                </Badge>
                <Badge variant="phoenix" bg="secondary">
                  Fermer
                </Badge>
              </div>
            </div>
            <ProgressBar variant="primary-200" now={40} className="mb-2" />
            <div className="d-flex align-items-center justify-content-between">
              <p className="mb-0">New</p>
              <div>
                <FeatherIcon
                  icon="clock"
                  className="lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">Dec 15, 05:00AM</span>
              </div>

            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default RetailerProfileCard;
