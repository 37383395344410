import React, { useState, ChangeEvent } from "react";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faFileImport,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import InboundRulesTable, { inboundRulesTableColumns } from "../../../../components/tables/ipbx/InboundRulesTable";
import { inboundRulesTableData } from "../../../../data/iper-x/ipbx/inboundRules";

const InboundRulesList = () => {
  const table = useAdvanceTable({
    data: inboundRulesTableData,
    columns: inboundRulesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openAddModal, setOpenAddModal] = useState(false);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddRuleClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setOpenAddModal(false);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Règles entrantes</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleAddRuleClick}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter une règle entrante
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
              >
                <FontAwesomeIcon icon={faFilter} transform="down-3" className="text-primary" />
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <InboundRulesTable />
        </div>
      </AdvanceTableProvider>

      <Modal show={openAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une règle entrante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
                  <Form.Group controlId="type">
                    <Form.Label>TYPE</Form.Label>
                    <Form.Control type="text" name="type" required />
                  </Form.Group>

                  <Form.Group controlId="name">
                    <Form.Label>NOM</Form.Label>
                    <Form.Control type="text" name="name" required />
                  </Form.Group>

                  <Form.Group controlId="trunk">
                    <Form.Label>TRUNK</Form.Label>
                    <Form.Control type="text" name="trunk" required />
                  </Form.Group>

                  <Form.Group controlId="sdaDidNumber">
                    <Form.Label>NUMÉRO SDA/DID</Form.Label>
                    <Form.Control type="text" name="sdaDidNumber" required />
                  </Form.Group>

                  <Form.Group controlId="officeHoursRouting">
                    <Form.Label>ROUTAGE HEURE DE BUREAU</Form.Label>
                    <Form.Control type="text" name="officeHoursRouting" required />
                  </Form.Group>

                  <Form.Group controlId="offHoursRouting">
                    <Form.Label>HORS BUREAU</Form.Label>
                    <Form.Control type="text" name="offHoursRouting" required />
                  </Form.Group>

                  <Button variant="primary" type="submit" className='mt-4'>
                    Ajouter
                  </Button>
                </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InboundRulesList;
