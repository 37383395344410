import MainLayout from 'layouts/MainLayout';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';

import React, { lazy } from 'react';
import DashboardRetailer from './pages/apps/retailer/DashboardRetailer';
import DashboardEditor from 'pages/apps/editor/DashboardEditor';
import UsersInEditor from 'pages/apps/editor/UsersInEditor';

import TicketsInEditor from 'pages/apps/editor/TicketsInEditor';
import OrderAndInvoicesInEditor from 'pages/apps/editor/OrderAndInvoicesInEditor';
import DetailsLeadDashboardInEditor from 'pages/apps/editor/DetailsLeadDashboardInEditor';
import UsersRightsDashboardInEditor from 'pages/apps/editor/UsersRightsDashboardInEditor copy';
import UsersList from './pages/apps/ipbx/UsersList';
import DevicesList from './pages/apps/ipbx/DevicesList';
import DetailsRetailerDashboardInEditor from 'pages/apps/editor/DetailsRetailerDashboardInEditor';
import TrunkSIPList from './pages/apps/ipbx/TrunkSIPList';
import BackupsList from './pages/apps/ipbx/BackupsList';
import DIALPlanWYSIWYG from './pages/apps/ipbx/DIALPlanWYSIWYG';
import ContactsList from './pages/apps/ipbx/ContactsList';
import CallLogsList from './pages/apps/ipbx/report/CallLogsList';
import UsersProfile from './pages/apps/ipbx/UsersProfile';
import RecordsList from './pages/apps/ipbx/report/RecordsList';
import ReportsList from './pages/apps/ipbx/report/ReportsList';
import CDRConfig from './pages/apps/ipbx/report/CDRConfig';
import FaqIpbx from './pages/apps/ipbx/FaqIpbx';
import SecurityConfig from './pages/apps/ipbx/security/SecurityConfig';
import AllowedCountry from './pages/apps/ipbx/security/AllowedCountry';
import RootCredentialsConfig from './pages/apps/ipbx/security/RootCredentialsConfig';
import BlacklistedNumbersList from './pages/apps/ipbx/security/BlacklistedNumbersList';
import RetailerUsersList from './pages/apps/retailer/users/RetailerUsersList';
import BlacklistedIPList from './pages/apps/ipbx/security/BlacklistedIPList';
import SecureSIPConfig from './pages/apps/ipbx/security/SecureSIPConfig';
import TunnelTrunksConfig from './pages/apps/ipbx/security/TunnelTrunksConfig';
import PhoneBookList from './pages/apps/ipbx/advance/PhoneBookList';
import TicketsList from './pages/apps/retailer/tickets/TicketsList';
import RetailerCertificationsHistoryList from './pages/apps/retailer/RetailerCertificationsHistoryList';
import DownloadClients from './pages/apps/retailer/DownloadClients';
import DashboardIpbx from './pages/apps/ipbx/DashboardIpbx';
import SignIn from './pages/auth/SignIn';
import AddExtensionDeviceForm from './pages/apps/ipbx/extension/AddExtensionDeviceForm';
import AddTrunkSIPForm from './pages/apps/ipbx/trunk-sip/AddTrunkSIPForm';
import Settings from './pages/apps/ipbx/settings/Settings';
import ChatConversation from 'pages/apps/old/chat/ChatConversation';
import ChatHomepage from 'pages/apps/old/chat/ChatHomepage';
import IperxMessagerie from 'pages/apps/webrtc/IperxMessagerie';
import IperxParamChat from 'pages/apps/webrtc/IperxParam/IperxParamChat';
import IperxParamAnnonce from 'pages/apps/webrtc/IperxParam/IperxParamAnnonce';
import IperxParamIntegration from 'pages/apps/webrtc/IperxParam/IperxParamIntegration';
import IperxParamQRcode from 'pages/apps/webrtc/IperxParam/IperxParamQRcode';
import IperxParamRaccourci from 'pages/apps/webrtc/IperxParam/IperxParamRaccourci';
import IperxParamRepository from 'pages/apps/webrtc/IperxParam/IperxParamRepository';
import IperxParamTransfertAppel from "pages/apps/webrtc/IperxParam/Transfert d'appel/IperxParamTransfertAppel";
import IperxParamVisualiser from 'pages/apps/webrtc/IperxParam/IperxParamVisualiser';
import IperxHistoryUserCalls from 'pages/apps/webrtc/IperxHistoryUserCalls';
import IperxCallingPage from 'pages/apps/webrtc/IperxCall/IperxCallingPage';
import IperxMessagePage from 'pages/apps/webrtc/IperxChat';
import IperxChat from 'pages/apps/webrtc/IperxChat';
import IperxMeetingPage from 'pages/apps/webrtc/IperxMeetingFiles/IperxMeetingPage';
import IperxUsersProfile from 'pages/apps/ipbx/IperxUsersProfile';
import IperxContactPage from 'pages/apps/webrtc/IperxContactPage';
import IperxCallingWaitingPage from 'pages/apps/webrtc/IperxCall/IperxCallingWaitingPage';
import IperxCallingTapPage from 'pages/apps/webrtc/IperxCall/IperxCallingTapPage';
import IperxParamAudioVideo from 'pages/apps/webrtc/IperxParam/IperxParamAudioVideo';
import IperxParam from 'pages/apps/webrtc/IperxParam/IperxParam';
import CodecsList from './pages/apps/ipbx/settings/apps/CodecsList';
import OptionsConfig from './pages/apps/ipbx/settings/apps/OptionsConfig';
import CallParkingConfig from './pages/apps/ipbx/settings/system/CallParkingConfig';
import ConferenceConfig from './pages/apps/ipbx/settings/system/ConferenceConfig';
import IperxCalendarCreateAnEvent from 'pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarCreateAnEvent';
import IperxCalendarEventDetail from 'pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarEventDetail';
import ShortcutCodesConfig from 'pages/apps/ipbx/settings/system/ShortcutCodesConfig';
import EmailConfig from './pages/apps/ipbx/settings/system/EmailConfig';
import GeneralConfig from './pages/apps/ipbx/settings/system/general/GeneralConfig';
import HoldMusicConfig from './pages/apps/ipbx/settings/system/HoldMusicConfig';
import VoiceInstructionsConfig from './pages/apps/ipbx/settings/system/VoiceInstructionsConfig';
import IperxCallingConference from 'pages/apps/webrtc/IperxCall/IperxCallingConference';
import IperxCallingConferenceVideo from 'pages/apps/webrtc/IperxCall/IperxCallingConferenceVideo';
import IperxMeetingCall from 'pages/apps/webrtc/IperxMeetingFiles/IperxMeetingCall';
import VoiceMessagingConfig from './pages/apps/ipbx/settings/system/VoiceMessagingConfig';
import IperxCallingPageVideo from 'pages/apps/webrtc/IperxCall/IperxCallingPageVideo';
import CRMIntegrationConfig from './pages/apps/ipbx/settings/integration/crm/CRMIntegrationConfig';
import IperxChatModal from 'pages/apps/webrtc/IperxParam/IperxChatModal';
import GoogleIntegrationConfig from './pages/apps/ipbx/settings/integration/google/GoogleIntegrationConfig';
import IperxCallingWaitingPageVideo from 'pages/apps/webrtc/IperxCall/IperxCallingWaitingPageVideo';
import HotelServicesConfig from './pages/apps/ipbx/settings/integration/hotel-services/HotelServicesConfig';
import Microsoft365IntegrationConfig from './pages/apps/ipbx/settings/integration/microsoft/Microsoft365IntegrationConfig';
import ReplacementE164Config from './pages/apps/ipbx/settings/advance/ReplacementE164Config';
import LanguageOfficeHoursConfig from 'pages/apps/ipbx/settings/advance/LanguageOfficeHoursConfig';
import UpdateConfig from './pages/apps/ipbx/settings/advance/UpdateConfig';
import RetailerLeadDetails from './pages/apps/retailer/leads/RetailerLeadDetails';
import RetailerDetails from './pages/apps/retailer/RetailerDetails';
import IperxUsers from 'pages/apps/webrtc/IperxUsers';
import IperxContactPageForm from 'pages/apps/webrtc/IperxContactPageForm';
import OrderAndInvoicesInRetailer from 'pages/apps/retailer/orders_invocies/OrderAndInvoicesInRetailer';
import IpbxUsersProfile from 'pages/apps/ipbx/IpbxUsersProfile';
import DashboardMain from './pages/dashboard/DashboardMain';
import Logout from './pages/auth/Logout';
import CallGroupsList from 'pages/apps/ipbx/gestion-des-appels/CallGroupsList';
import CallQueuesList from 'pages/apps/ipbx/gestion-des-appels/CallQueuesList';
import InboundRulesList from 'pages/apps/ipbx/gestion-des-appels/InboundRulesList';
import OutboundRulesList from 'pages/apps/ipbx/gestion-des-appels/OutboundRulesList';
import VoicemailList from 'pages/apps/ipbx/gestion-des-appels/VoicemailList';
import TicketDetail from "./pages/apps/retailer/tickets/TicketDetail";
import OrderList from "./pages/apps/retailer/orders_invocies/OrderList";
import InvoicesList from 'pages/apps/retailer/orders_invocies/InvoicesList';

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <DashboardMain />
          },
          {
            path: 'dashboard',
            element: <DashboardMain />
          },
          {
            path: 'dashboard/revendeur',
            element: <DashboardRetailer />
          },
          {
            path: 'dashboard/editeur',
            element: <DashboardEditor />
          },
          {
            path: '/portail-revendeur',
            children: [
              {
                path: 'commandes-et-factures',
                element: <OrderAndInvoicesInRetailer />
              },
              {
                path: 'commandes',
                element: <OrderList />
              },
              {
                path: 'factures',
                element: <InvoicesList />
              },
              {
                path: 'utilisateurs',
                element: <RetailerUsersList />
              },
              {
                path: 'utilisateurs/details/:name',
                element: <UsersProfile />
              },
              {
                path: 'vos-tickets',
                element: <TicketsList />
              },
              {
                path: 'vos-tickets/:id',
                element: <TicketDetail />
              },
              {
                path: 'gestion-des-droits-utilisateurs',
                element: <RetailerUsersList />
              },
              {
                path: 'certifications',
                element: <RetailerCertificationsHistoryList />
              },
              {
                path: 'téléchargements',
                element: <DownloadClients />
              },
              {
                path: 'parametres',
                element: <RetailerUsersList />
              },

              {
                path: 'lead/:name',
                element: <RetailerLeadDetails />
              },

              {
                path: 'revendeur/:name',
                element: <RetailerDetails />
              }
            ]
          },
          {
            path: '/portail-editeur',
            children: [

              {
                path: 'utilisateurs',
                element: <UsersInEditor />
              },
              {
                path: 'utilisateurs/details/:name',
                element: <UsersProfile />
              },
              {
                path: 'vos-tickets',
                element: <TicketsInEditor />
              },
              {
                path: 'commandes-et-factures',
                element: <OrderAndInvoicesInEditor />
              },
              {
                path: 'utilisateurs/gestion-des-droits-utlisateurs/:name',
                element: <UsersRightsDashboardInEditor />
              },
              {
                path: 'leads/:name',
                element: <DetailsLeadDashboardInEditor />
              },
              {
                path: 'revendeur/:name',
                element: <DetailsRetailerDashboardInEditor />
              },
              {
                path: 'parametres',
                element: <RetailerUsersList />
              }
            ]
          },

          {
            path: 'ipbx',
            children: [
              {
                path: 'dashboard',
                element: <DashboardIpbx />
              },
              {
                path: 'utilisateurs',
                element: <UsersList />
              },
              {
                path: 'utilisateurs/details/:name',
                element: <IpbxUsersProfile />
              },
              {
                path: 'téléphones',
                element: <DevicesList />
              },
              {
                path: 'téléphones/ajouter',
                element: <AddExtensionDeviceForm />
              },
              {
                path: 'trunk-sip',
                element: <TrunkSIPList />
              },
              {
                path: 'trunk-sip/ajouter',
                element: <AddTrunkSIPForm />
              },
              {
                path: 'gestion-des-appels/editor',
                element: <DIALPlanWYSIWYG />
              },
              {
                path: 'gestion-des-appels/regles-entrantes',
                element: <InboundRulesList />
              },
              {
                path: 'gestion-des-appels/regles-sortantes',
                element: <OutboundRulesList />
              },
              {
                path: 'gestion-des-appels/repondeurs-numeriques',
                element: <VoicemailList />
              },
              {
                path: 'gestion-des-appels/groupes-d-appels',
                element: <CallGroupsList />
              },
              {
                path: 'gestion-des-appels/files-d-attente',
                element: <CallQueuesList />
              },
              {
                path: 'sauvegardes',
                element: <BackupsList />
              },
              {
                path: 'contacts',
                element: <ContactsList />
              },
              {
                path: 'rapport/journaux-appels',
                element: <CallLogsList />
              },
              {
                path: 'rapport/parametres-cdr',
                element: <CDRConfig />
              },
              {
                path: 'rapport/rapports',
                element: <ReportsList />
              },
              {
                path: 'rapport/enregistrements',
                element: <RecordsList />
              },
              {
                path: 'securite/anti-piratage',
                element: <SecurityConfig />
              },
              {
                path: 'securite/codes-de-pays-autorises',
                element: <AllowedCountry />
              },
              {
                path: 'securite/identifiants-racine',
                element: <RootCredentialsConfig />
              },
              {
                path: 'securite/numeros-en-liste-noire',
                element: <BlacklistedNumbersList />
              },
              {
                path: 'securite/liste-noire-IP-adresses',
                element: <BlacklistedIPList />
              },
              {
                path: 'securite/SIP-sécurisé',
                element: <SecureSIPConfig />
              },
              {
                path: 'securite/tunnel-trunks',
                element: <TunnelTrunksConfig />
              },
              {
                path: 'avancés/annuaire',
                element: <PhoneBookList />
              },
              {
                path: 'faq',
                element: <FaqIpbx />
              },
              {
                path: 'parametres',
                element: <Settings />
              },
              {
                path: 'parametres/codecs',
                element: <CodecsList />
              },
              {
                path: 'parametres/options',
                element: <OptionsConfig />
              },
              {
                path: 'parametres/call-parking-config',
                element: <CallParkingConfig />
              },
              {
                path: 'parametres/conference',
                element: <ConferenceConfig />
              },
              {
                path: 'parametres/shortcut-codes',
                element: <ShortcutCodesConfig />
              },
              {
                path: 'parametres/email',
                element: <EmailConfig />
              },
              {
                path: 'parametres/general',
                element: <GeneralConfig />
              },
              {
                path: 'parametres/hold-music',
                element: <HoldMusicConfig />
              },
              {
                path: 'parametres/voice-instructions',
                element: <VoiceInstructionsConfig />
              },
              {
                path: 'parametres/voice-messaging',
                element: <VoiceMessagingConfig />
              },
              {
                path: 'parametres/crm-integration',
                element: <CRMIntegrationConfig />
              },
              {
                path: 'parametres/google-integration',
                element: <GoogleIntegrationConfig />
              },
              {
                path: 'parametres/hotel-services',
                element: <HotelServicesConfig />
              },
              {
                path: 'parametres/microsoft-integration',
                element: <Microsoft365IntegrationConfig />
              },
              {
                path: 'parametres/replacement-e164',
                element: <ReplacementE164Config />
              },
              {
                path: 'parametres/language-office-hours',
                element: <LanguageOfficeHoursConfig />
              },
              {
                path: 'parametres/update',
                element: <UpdateConfig />
              }
            ]
          },
          {
            path: 'webrtc',
            children: [
              {
                path: 'utilisateurs',
                element: <IperxUsers />
              },
              {
                path: 'utilisateurs/details/:name',
                element: <IperxUsersProfile />
              },
              {
                path: 'chat',
                element: <IperxChat />,
                children: [
                  {
                    index: true,
                    element: <ChatHomepage />
                  },
                  {
                    path: ':userId/conversation',
                    element: <ChatConversation />
                  },
                  {
                    path: ':userId/appel/conversation',
                    element: <IperxChatModal />
                  }
                  //A garder , hésitation sur l'utilisation du chat unique , id de préférence//
                  // {
                  //   path: ':userId/conversation',
                  //   element: <IperxUniqueChat />
                  // }
                ]
              },
              {
                path: 'reunion',
                element: <IperxMeetingPage />
              },
              {
                path: 'reunion/appel',
                element: <IperxMeetingCall />
              },
              {
                path: 'reunion-events',
                children: [
                  {
                    path: 'create-an-event',
                    element: <IperxCalendarCreateAnEvent />
                  },
                  {
                    path: 'event-detail',
                    element: <IperxCalendarEventDetail />
                  }
                ]
              },
              {
                path: 'historique-appel',
                element: <IperxHistoryUserCalls />
              },
              {
                path: 'utilisateurs/appel',
                element: <IperxCallingTapPage />
              },
              {
                path: 'utilisateurs/appel-waiting/:name',
                element: <IperxCallingWaitingPage show={false} />
              },
              {
                path: 'utilisateurs/appel-waiting/:name/video',
                element: <IperxCallingWaitingPageVideo show={false} />
              },
              {
                path: 'utilisateurs/appel/:name',
                element: <IperxCallingPage show={false} />
              },
              {
                path: 'utilisateurs/appel/:name/video',
                element: <IperxCallingPageVideo show={false} />
              },
              {
                path: 'utilisateurs/appel/conference',
                element: <IperxCallingConference show={false} />
              },
              {
                path: 'utilisateurs/appel/conference/video',
                element: <IperxCallingConferenceVideo show={false} />
              },
              {
                path: 'utilisateurs/message/:name',
                element: <IperxMessagePage />
              },
              {
                path: 'utilisateurs/details/:name',
                element: <IperxUsersProfile />
              },
              {
                path: 'contact',
                element: <IperxContactPage />
              },
              {
                path: 'contact/formulaire',
                element: <IperxContactPageForm />
              },
              {
                path: 'messagerie',
                element: <IperxMessagerie />
              },
              {
                path: 'faq',
                element: <FaqIpbx />
              },
              {
                path: 'parametres',
                element: <IperxParam />,
                children: [
                  {
                    path: 'annonce',
                    element: <IperxParamAnnonce />
                  },
                  {
                    path: 'audio-video',
                    element: <IperxParamAudioVideo />
                  },
                  {
                    path: 'chat',
                    element: <IperxParamChat />
                  },
                  {
                    path: 'integration',
                    element: <IperxParamIntegration />
                  },
                  {
                    path: 'qrcode',
                    element: <IperxParamQRcode />
                  },
                  {
                    path: 'raccourci',
                    element: <IperxParamRaccourci />
                  },
                  {
                    path: 'repertoire',
                    element: <IperxParamRepository />
                  },
                  {
                    path: 'transfert-appel',
                    element: <IperxParamTransfertAppel />
                  },
                  {
                    path: 'visualiser',
                    element: <IperxParamVisualiser />
                  }
                ]
              }
            ]
          },

          {
            path: 'faq',
            element: <FaqIpbx />
          },
        ]
      },


      {
        path: 'login',
        element: <SignIn />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
