import React, { useState } from 'react';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { editorOrdersAndInvoicesBreadcrumbItems } from 'data/breadcrumbItem';
import { faFileExport, faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import FilterButtonGroup, {
  FilterMenu
} from 'components/common/FilterButtonGroup';
import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import EditorOrdersTable, { editorOrdersTableColumns } from "../../../components/tables/iper-x/editor/EditorOrdersTable";
import { editorOrdersTableData } from "../../../data/iper-x/editor/orders";
import { Link } from 'react-router-dom';

const tabItems: FilterTabItem[] = [
  {
    label: 'Tout',
    value: 'all',
    count: 68817
  },
  {
    label: 'En attente de paiement',
    value: 'pending_payment',
    count: 6
  },
];

const filterMenus: FilterMenu[] = [
  {
    label: 'Statut de paiement',
    items: [
      {
        label: 'Action'
      },
      {
        label: 'Autre action'
      },
      {
        label: 'Quelque chose d\'autre ici'
      }
    ]
  },
  {
    label: 'Statut d\'accomplissement',
    items: [
      {
        label: 'Action'
      },
      {
        label: 'Autre action'
      },
      {
        label: 'Quelque chose d\'autre ici'
      }
    ]
  }
];

const OrderAndInvoicesInEditor = () => {
  const table = useAdvanceTable({
    data: editorOrdersTableData,
    columns: editorOrdersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const [showExportModal, setShowExportModal] = useState(false);
  const toggleExportModal = () => setShowExportModal(!showExportModal);

  const [exportOptions, setExportOptions] = useState({
    includeInvoice: true,
    includeOrder: true,
  });

  const handleExport = () => {
    // Export des éléments sélectionnés
    toggleExportModal();
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setExportOptions(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const toggleAddOrderModal = () => setShowAddOrderModal(!showAddOrderModal);

  const [order, setOrder] = useState({
    FACTURE: '',
    'BON DE LIVRAISON': '',
    COMMANDE: '',
    TOTAL: '',
    CLIENT: '',
    'STATUT DE PAIEMENT': '',
    'STATUT D\'ACCOMPLISSEMENT': '',
    'TYPE DE LIVRAISON': '',
    'DATE FACTURATION': '',
    'DATE COMMANDE': '',
    'DATE LIVRAISON': ''
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOrder(prevOrder => ({
      ...prevOrder,
      [name]: value
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    toggleAddOrderModal();
  };

  return (
    <>
      <div>
        <Row className="gy-3 mb-6 justify-content-between">
          <Col md={9} xs="auto">
            <PageBreadcrumb items={editorOrdersAndInvoicesBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Commandes et factures</h2>
          </Col>
        </Row>
        <FilterTab tabItems={tabItems} className="mb-2" />

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <div className="d-flex flex-wrap gap-3">
              <SearchBox
                placeholder="Rechercher des commandes"
                onChange={handleSearchInputChange}
              />
              <div className="ms-xxl-auto">
                <Button variant="link" className="text-900 me-4 px-0">
                  <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                  Importer
                </Button>
                <Button variant="link" className="text-900 me-4 px-0" onClick={toggleExportModal}>
                  <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                  Exporter
                </Button>
                <Button variant="primary" onClick={toggleAddOrderModal}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Ajouter une commande
                </Button>
              </div>
            </div>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <EditorOrdersTable />
          </div>
        </AdvanceTableProvider>

        <Modal show={showExportModal} onHide={toggleExportModal}>
          <Modal.Header closeButton>
            <Modal.Title>Exporter commande et facture</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Check
                type="checkbox"
                id="includeInvoiceOrder"
                name="includeInvoiceOrder"
                label="Facture et commande"
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                id="includeOrder"
                name="includeOrder"
                label="Facture"
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                id="includeOrder"
                name="includeOrder"
                label="Commande"
                onChange={handleCheckboxChange}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleExportModal}>
              Annuler
            </Button>
            <Button variant="primary" onClick={handleExport}>
              Exporter
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showAddOrderModal} onHide={toggleAddOrderModal}>
          <Modal.Header closeButton>
            <Modal.Title>Ajouter une commande</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="FACTURE">
                <Form.Label>NUMÉRO DE FACTURE</Form.Label>
                <Form.Control type="text" name="FACTURE" value={order.FACTURE} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="BON DE LIVRAISON">
                <Form.Label>NUMÉRO DE BON DE LIVRAISON</Form.Label>
                <Form.Control type="text" name="BON DE LIVRAISON" value={order['BON DE LIVRAISON']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="COMMANDE">
                <Form.Label>NUMÉRO DE COMMANDE</Form.Label>
                <Form.Control type="text" name="COMMANDE" value={order.COMMANDE} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="TOTAL">
                <Form.Label>TOTAL</Form.Label>
                <Form.Control type="text" name="TOTAL" value={order.TOTAL} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="CLIENT">
                <Form.Label>CLIENT</Form.Label>
                <Form.Control type="text" name="CLIENT" value={order.CLIENT} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="STATUT DE PAIEMENT">
                <Form.Label>STATUT DE PAIEMENT</Form.Label>
                <Form.Control type="text" name="STATUT DE PAIEMENT" value={order['STATUT DE PAIEMENT']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="STATUT D'ACCOMPLISSEMENT">
                <Form.Label>STATUT D'ACCOMPLISSEMENT</Form.Label>
                <Form.Control type="text" name="STATUT D'ACCOMPLISSEMENT" value={order['STATUT D\'ACCOMPLISSEMENT']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="TYPE DE LIVRAISON">
                <Form.Label>TYPE DE LIVRAISON</Form.Label>
                <Form.Control type="text" name="TYPE DE LIVRAISON" value={order['TYPE DE LIVRAISON']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="DATE FACTURATION">
                <Form.Label>DATE FACTURATION</Form.Label>
                <Form.Control type="date" name="DATE FACTURATION" value={order['DATE FACTURATION']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="DATE COMMANDE">
                <Form.Label>DATE COMMANDE</Form.Label>
                <Form.Control type="date" name="DATE COMMANDE" value={order['DATE COMMANDE']} onChange={handleChange} required />
              </Form.Group>

              <Form.Group controlId="DATE LIVRAISON">
                <Form.Label>DATE LIVRAISON</Form.Label>
                <Form.Control type="date" name="DATE LIVRAISON" value={order['DATE LIVRAISON']} onChange={handleChange} required />
              </Form.Group>

              <Button variant="primary" type="submit" className='mt-4 mb-4'>
                Ajouter
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default OrderAndInvoicesInEditor;
