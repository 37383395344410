import React, { useState } from 'react';
import { Button, Col, Modal, Form, Row } from 'react-bootstrap';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent } from 'react';
import { usersEditorTableData } from 'data/iper-x/ipbx/usersInEditor';
import UsersTableInEditor, { usersTableColumnsInEditor } from 'components/tables/ipbx/UsersTableInEditor';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import { faFileExport, faFileImport, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UsersInEditor = () => {
  const table = useAdvanceTable({
    data: usersEditorTableData,
    columns: usersTableColumnsInEditor,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [user, setUser] = useState({
    username: '',
    function: '',
    role: '',
    certification: '',
    email: '',
    mobile: '',
    phone: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    handleCloseModal();
  };

  const handleSearchInputChange = (e) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Utilisateurs</span>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300" placeholder="Rechercher" onChange={handleSearchInputChange} />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
              <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleShowModal}>
                Ajouter un utilisateur
              </Button>
              <Button variant="phoenix-secondary" className="px-3 ms-2" onClick={() => setOpenFilterModal(true)}>
                <FontAwesomeIcon icon={faFilter} transform="down-3" className="text-primary" />
              </Button>
              <LeadsFilterModal show={openFilterModal} handleClose={() => setOpenFilterModal(false)} />
            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <UsersTableInEditor />
        </div>
      </AdvanceTableProvider>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Nom d'utilisateur</Form.Label>
              <Form.Control type="text" name="username" value={user.username} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="function">
              <Form.Label>Fonction</Form.Label>
              <Form.Control as="select" name="function" value={user.function} onChange={handleChange} required>
                <option value="">Sélectionnez une fonction</option>
                <option value="responsable">Responsable</option>
                <option value="directeur">Directeur</option>
                <option value="pdg">PDG</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="role">
              <Form.Label>Rôle</Form.Label>
              <Form.Control as="select" name="role" value={user.role} onChange={handleChange} required>
                <option value="">Sélectionnez un rôle</option>
                <option value="technique">Technique</option>
                <option value="commercial">Commercial</option>
                <option value="financière">Financière</option>
                <option value="pdg">PDG</option>
                <option value="adv">ADV</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="certification">
              <Form.Label>Certification</Form.Label>
              <Form.Control as="select" name="certification" value={user.certification} onChange={handleChange} required>
                <option value="">Sélectionnez une certification</option>
                <option value="technique">Technique</option>
                <option value="commercial">Commercial</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={user.email} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="mobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control type="text" name="mobile" value={user.mobile} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Téléphone fixe</Form.Label>
              <Form.Control type="text" name="phone" value={user.phone} onChange={handleChange} required />
            </Form.Group>

            <Button variant="primary" type="submit" className='mt-4'>
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UsersInEditor;
