import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import axios from 'axios';
import iconSilver from 'assets/img/iper-x/ranks/icons/Silver.png';
import iconGold from 'assets/img/iper-x/ranks/icons/Gold.png';
import iconPlatinium from 'assets/img/iper-x/ranks/icons/Platinium.png';

// Définition de l'interface pour les applications
interface AppAccess {
  img: string;
  title: string;
  width?: string;
}

// Données de fallback
const fallbackApps: AppAccess[] = [
  { img: iconPlatinium, title: 'Ipercom', width: '30' },
  // { img: iconGold, title: 'Iperx', width: '30'  },
  { img: iconPlatinium, title: 'Ipos', width: '30'  },
  { img: iconPlatinium, title: 'Iper-cloud', width: '30'  },
  { img: iconPlatinium, title: 'Webrtc', width: '30'  }
];

const NineDotMenu: React.FC = () => {
  const [apps, setApps] = useState<AppAccess[]>(fallbackApps);
  const [error, setError] = useState<boolean>(false);

  // Utilisation de useEffect pour récupérer les données de l'API
  useEffect(() => {
    axios.get('/api/AppsAccess')
      .then(response => {
        // Mappez les données de l'API vers l'interface AppAccess
        const fetchedApps: AppAccess[] = response.data.map((app: any) => ({
          img: getAppIcon(app.title), // Fonction pour obtenir l'icône en fonction du titre de l'application
          title: app.title,
          width: '0' // Utilisation de la même largeur pour toutes les icônes
        }));
        setApps(fetchedApps);
        setError(false);
      })
      .catch(() => {
        setApps(fallbackApps);
        setError(true);
      });
  }, []);

  // Fonction pour obtenir l'icône appropriée en fonction du titre de l'application
  const getAppIcon = (title: string): string => {
    switch (title) {
      case 'Ipercom':
        return iconPlatinium;
      case 'Iperx':
        return iconGold;
      case 'Ipos':
        return iconSilver;
      case 'Iper-cloud':
        return iconPlatinium;
      case 'Webrtc':
        return iconPlatinium;
      default:
        return iconPlatinium; // Icône par défaut
    }
  };

  return (
    <Dropdown.Menu
      align="end"
      className="navbar-dropdown-caret py-0 dropdown-nine-dots shadow border border-300"
    >
      <Card
        className="position-relative border-0"
        style={{ height: '20rem', minWidth: 244 }}
      >
        <Scrollbar>
          <Card.Body className="pt-3 px-3 pb-0">
            <Row className="text-center align-items-center g-0">
              {apps.map(app => (
                <Col xs={4} key={app.title}>
                  <Link
                    to="#!"
                    className="d-block hover-bg-200 p-2 rounded-3 text-center text-decoration-none mb-3"
                  >
                    <img
                      src={app.img}
                      alt={app.title}
                      width={app.width}
                    />
                    <p className="mb-0 text-black text-truncate fs-10 mt-1 pt-1">
                      {app.title}
                    </p>
                  </Link>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Scrollbar>
      </Card>
    </Dropdown.Menu>
  );
};

export default NineDotMenu;
