import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";
import { Trunks } from "../../../data/iper-x/ipbx/trunks";
import RevealDropdown, { RevealDropdownTrigger } from "../../base/RevealDropdown";
import ActionDropdownItems from "../../common/ActionDropdownItems";

export const trunksTableColumns: ColumnDef<Trunks>[] = [
  {
    id: "status",
    accessorFn: ({ status }) => status,
    header: "Statut",
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge
          bg={status === "Actif" ? "success" : "secondary"}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
        >
          {status}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: "10%" }, className: "pe-3" }
    }
  },
  {
    accessorKey: "name",
    header: "Nom",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "host",
    header: "Hote",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "type",
    header: "Type",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "simultaneous_calls",
    header: "Appels simultanés",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "main_trunk_number",
    header: "No.trunk principal",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "sent_record",
    header: "Enregistrement envoyé",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "record_ok",
    header: "Enregistrement Ok",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "last_failure",
    header: "Dernier échec",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    id: 'leadDropdown',
    header: "Gérer",
    cell: () => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownItems />
            {/*<DevicesDropdownItems />*/}

          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
  },
];

const TrunksTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default TrunksTable;
