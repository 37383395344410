export interface CallQueues {
  extension: string; // Ext.
  queueName: string; // Nom de la file
  scuttingStrategy: string; // Stratégie de scutation
  timeout: number; // délai dépassé
  queueLanguage: string; // Langue de la file
  // Autres champs si nécessaire
}

export const callQueuesTableData: CallQueues[] = [
  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },
  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },  {
    extension: "81", // Valeur exemple pour Ext.
    queueName: "Accueil", // Valeur exemple pour Nom de la file
    scuttingStrategy: "Sonne tous", // Valeur exemple pour Stratégie de scutation
    timeout: 15, // Valeur exemple pour délai dépassé
    queueLanguage: "French prompts set", // Valeur exemple pour Langue de la file
    // Initialiser d'autres champs si nécessaire
  },
];
