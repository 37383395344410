import team32 from "assets/img/team/32.webp";
import commerciale from "assets/img/iper-x/certification/commerciale.png"
import technique from "assets/img/iper-x/certification/technique.png"

import { Status } from "components/base/Avatar";
import { BadgeBg } from "components/base/Badge";

export interface UsersInEditor {

  user_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };

  user: {
    avatar?: string;
    name: string;
    status: Status;
    placeholder?: boolean;
    certification: string;
  };
  rôle: string;
  fonction: string;
  email: string;
  mobile: string;
  fixe: string;
  devices: number;
  sync_time: string;


}

export const usersEditorTableData: UsersInEditor[] = [
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,
      
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Commerciale",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: technique,
      
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "PDG",
    fonction: "PDG",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Vladimir radov",
      status: "offline",
      certification: technique,
      
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Directeur",
    fonction: "Financière",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
  {

    user: {
      avatar: team32,
      name: "Soutin parsi",
      status: "offline",
      certification: technique,
      
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "ADV",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },{

    user: {
      avatar: team32,
      name: "Carry Anna",
      status: "offline",
      certification: commerciale,
    },
    user_status: {
      label: "Disponible",
      type: "success",
      icon: "check"
    },
    rôle: "Responsable",
    fonction: "Technique",
    email: "MilindMikuja@gmail.com",
    mobile: "064890201",
    fixe: "0556246219",
    devices: 1,
    sync_time: "34 minute"
  },
];
