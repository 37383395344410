import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { currencyFormat } from 'helpers/utils';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';

import Badge from 'components/base/Badge';
import FeatherIcon from 'feather-icons-react';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Invoice } from '../../../../hooks/dolibarr/useInvoicesData';

export const invoicesTableColumns: ColumnDef<Invoice>[] = [
  {
    accessorKey: 'ref',
    header: 'Réf.',
    cell: ({ row: { original } }) => {
      const { ref } = original;
      return <InvoicePreviewLink invoice={original} />;
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3 ps-0' },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'date_creation',
    header: 'Date facturation',
    cell: ({ getValue }) => {
      const value = getValue<number>();
      return new Date(value * 1000).toLocaleDateString();
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'date_validation',
    header: 'Date échéance',
    cell: ({ getValue }) => {
      const value = getValue<number>();
      return value ? new Date(value * 1000).toLocaleDateString() : '';
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'tiers',
    header: 'Tiers',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'total_ht',
    header: 'Montant HT',
    cell: ({ row: { original } }) => currencyFormat(original.total_ht),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end fw-semi-bold text-1000' }
    }
  },
  {
    accessorKey: 'total_ttc',
    header: 'Montant TTC',
    cell: ({ row: { original } }) => currencyFormat(original.total_ttc),
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end fw-semi-bold text-1000' }
    }
  },
  {
    accessorKey: 'author',
    header: 'Créé par',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'ps-0' }
    }
  },
  {
    accessorKey: 'status',
    header: 'État',
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <Badge
          bg={status === '1' ? 'warning' : 'success'}
          variant="phoenix"
          iconPosition="end"
          className="fs-10"
          icon={
            <FeatherIcon
              icon={status === '1' ? 'clock' : 'check-circle'}
              size={12.8}
              className="ms-1"
            />
          }
        >
          {status === '1' ? 'Impayée' : 'Payée'}
        </Badge>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'pe-3' }
    }
  }
];

const InvoicePreviewLink: React.FC<{ invoice: Invoice }> = ({ invoice }) => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <Link to="#" onClick={handleShow} className="fw-semi-bold">
        {invoice.ref}
      </Link>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Preview for {invoice.ref}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="dialogforpopup"
            style={{
              width: 'auto',
              minHeight: '0px',
              maxHeight: 'none',
              height: '706.012px'
            }}
            className="ui-dialog-content ui-widget-content"
          >
            <embed
              src={`https://erp.ipercom.io/document.php?modulepart=facture&attachment=0&file=${invoice.ref}/${invoice.ref}.pdf&&entity=1`}
              type="application/pdf"
              width="100%"
              height="98%"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const InvoicesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default InvoicesTable;
