import React, { useState } from 'react';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { OutboundRules } from '../../../data/iper-x/ipbx/outboundRules';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';

// Define your table columns
export const outboundRulesTableColumns: ColumnDef<OutboundRules>[] = [
  {
    accessorKey: 'ruleName',
    header: 'Nom de la règle',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'extCalls',
    header: 'Appels de l’ext',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'prefix',
    header: 'Préfixe',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'length',
    header: 'Longueur',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'extensionGroup',
    header: 'Groupe d’extension',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'route1',
    header: 'Route 1',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'route2',
    header: 'Route 2',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'route3',
    header: 'Route 3',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'route4',
    header: 'Route 4',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    accessorKey: 'route5',
    header: 'Route 5',
    meta: {
      headerProps: { style: { width: '8%' } },
      cellProps: { className: 'text-900 fs-9' },
    },
  },
  {
    id: 'leadDropdown',
    header: 'Gérer',
    cell: ({ row }) => {
      const [showForm, setShowForm] = useState(false);

      const toggleForm = () => {
        setShowForm(!showForm);
      };

      return (
        <>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Dropdown.Item eventKey="1" onClick={toggleForm}>
                Modifier
              </Dropdown.Item>
            </RevealDropdown>
          </RevealDropdownTrigger>
          {showForm && (
            <Modal show={showForm} onHide={toggleForm}>
              <Modal.Header closeButton>
          <Modal.Title>Modifier la règle sortante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <Form>
    <Form.Group controlId="ruleName">
      <Form.Label>Nom de la règle</Form.Label>
      <Form.Control type="text" name="ruleName" required />
    </Form.Group>

    <Form.Group controlId="externalCalls">
      <Form.Label>Appels de l’ext</Form.Label>
      <Form.Control type="text" name="externalCalls" required />
    </Form.Group>

    <Form.Group controlId="prefix">
      <Form.Label>Préfixe</Form.Label>
      <Form.Control type="text" name="prefix" required />
    </Form.Group>

    <Form.Group controlId="length">
      <Form.Label>Longueur</Form.Label>
      <Form.Control type="text" name="length" required />
    </Form.Group>

    <Form.Group controlId="extensionGroup">
      <Form.Label>Groupe d’extension</Form.Label>
      <Form.Control type="text" name="extensionGroup" required />
    </Form.Group>

    <Form.Group controlId="route1">
      <Form.Label>Route 1</Form.Label>
      <Form.Control type="text" name="route1" required />
    </Form.Group>

    <Form.Group controlId="route2">
      <Form.Label>Route 2</Form.Label>
      <Form.Control type="text" name="route2" required />
    </Form.Group>

    <Form.Group controlId="route3">
      <Form.Label>Route 3</Form.Label>
      <Form.Control type="text" name="route3" required />
    </Form.Group>

    <Form.Group controlId="route4">
      <Form.Label>Route 4</Form.Label>
      <Form.Control type="text" name="route4" required />
    </Form.Group>

    <Form.Group controlId="route5">
      <Form.Label>Route 5</Form.Label>
      <Form.Control type="text" name="route5" required />
    </Form.Group>

    <Button variant="primary" type="submit" className='mt-4'>
      Ajouter
    </Button>
  </Form>
</Modal.Body>

            </Modal>
          )}
        </>
      );
    },
    meta: {
      headerProps: { style: { width: '1%' } },
      cellProps: {
        className: 'text-center',
      },
    },
  },
];

const OutboundRulesTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default OutboundRulesTable;
