import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBullhorn,
  faCalendarXmark,
  faCircleNodes,
  faFileInvoiceDollar,
  faPeace,
  faPieChart,
  faSliders,
  faTruckFast
} from '@fortawesome/free-solid-svg-icons';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const faqBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'FAQ',
    active: true
  }
];

export interface Faq {
  id: number;
  title: string;
  details: string;
}

export const faqs: Faq[] = [
  {
    id: 1,
    title: 'Quelle est votre politique de retour ?',
    details: `Au moment de l'expédition, nous garantissons que votre produit sera exempt
	de défauts de matériaux et de fabrication et qu'il sera conforme aux
	spécifications indiquées sur la fiche technique spécifique au lot incluse
	avec le produit. Veuillez contacter notre service de support technique si
	vous rencontrez un problème technique avec un produit : Email :
	<a href="mailto:phoenix@email.com">
		phoenix@support.com.
	</a>
	Si l'équipe conclut que le produit ne répond pas aux
	exigences mentionnées sur la fiche technique, nous fournirons un remplacement gratuit
	ou un remboursement intégral du prix de la facture du produit.`
  },
  {
    id: 2,
    title: `J'ai commandé le mauvais produit. Que dois-je faire ?`,
    details: `Nous envisagerions d'accepter le retour de la marchandise, moyennant des frais de restockage de 20 % plus les frais d'expédition et de manutention. Le client est responsable des frais d'expédition pour le produit retourné et le nouveau produit de remplacement, à la fois vers et depuis notre installation. Tous les retours nécessitent une autorisation préalable de notre part et doivent nous être renvoyés dans les sept jours ouvrables suivant la réception des marchandises. Les produits doivent être retournés dans le même emballage ou équivalent (c.-à-d. froid et isolé) dans lequel ils ont été expédiés (c.-à-d. froid et isolé). Une fois que nous recevons l'article, nous expédierons l'article de remplacement.`
  },
  {
    id: 3,
    title: `Comment puis-je annuler ma commande ?`,
    details: `Si vous devez annuler votre commande, veuillez appeler <a href="tel:+871406-7509">(871) 406-7509</a> Veuillez noter que nous essayons de traiter et d'expédier les commandes le même jour (si reçues avant 13h, heure du Pacifique), et une fois que votre produit a été expédié, notre politique de retour s'appliquera.`
  },
  {
    id: 4,
    title: `Quels sont vos frais de livraison et de manutention ?`,
    details: `Notre frais de manutention est un tarif fixe de 20 $. Les frais d'expédition varient en fonction de votre emplacement et des articles que vous avez achetés. Pour une estimation précise des frais de livraison, veuillez procéder au processus de paiement et entrer vos articles et votre adresse.`
  },
  {
    id: 6,
    title: `Acceptez-vous les bons de commande ?`,
    details: `Oui. Veuillez inclure votre numéro de bon de commande lors du paiement en ligne, ou l'indiquer clairement sur un bon de commande envoyé par télécopie ou par courriel. Veuillez fournir l'adresse e-mail ou postale pertinente pour votre service des comptes créditeurs afin que la facture puisse être envoyée à l'endroit approprié.`
  }
];

export interface FaqType {
  question: string;
  answer: string;
}

export interface FaqCategory {
  id: string;
  name: string;
  icon: IconProp;
  description: string;
  category?: string;
  topFaqs: FaqType[];
  faqs: FaqType[];
}

export const faqCategories: FaqCategory[] = [
  {
    id: 'sale-101',
    name: 'Ventes',
    icon: faPieChart,
    description:
      'Répondez aux questions les plus fréquemment posées sur vos produits et services ici.',
    category: 'popular',
    topFaqs: [
      {
        question: 'Comment puis-je acheter vos services ?',
        answer:
          'Vous pouvez nous envoyer un email à info@phoenix.template ou visiter notre page de services pour choisir et payer directement les services que nous proposons.'
      },
      {
        question: 'Combien coûtent vos services ?',
        answer:
          'Nos services peuvent être obtenus à un coût minimum. Veuillez visiter info.phoenix-tw.com pour obtenir des informations sur les meilleurs plans d’achat.'
      },
      {
        question: 'Offrez-vous une garantie de remboursement ?',
        answer:
          'Nous offrons des remboursements aux clients éligibles selon nos conditions générales et nos politiques.'
      }
    ],
    faqs: [
      {
        question: 'Offrez-vous un service client pour vos clients ?',
        answer:
          'Oui. Nous avons une équipe enthousiaste de fournisseurs de service client pour vous aider à résoudre tout problème pertinent qui pourrait survenir lors de l\'utilisation des services. Veuillez contacter support.phoenix.themewagon pour plus d’informations.'
      },
      {
        question: 'Offrez-vous une démo/version d’essai de vos services ?',
        answer:
          'Non, nous ne proposons pas d’option de réservation préalable ou d’essai. Vous pouvez contacter notre équipe de support pour plus d’informations.'
      },
      {
        question: 'Avec quelles devises Phoenix travaille-t-il ?',
        answer:
          'Nous permettons le retour de tous les articles dans les 30 jours suivant la date de votre commande initiale. Si vous souhaitez retourner vos articles, envoyez-nous un email avec votre numéro de commande.'
      },
      {
        question: 'Comment puis-je faire du dropshipping avec Phoenix ?',
        answer:
          'Nous permettons le retour de tous les articles dans les 30 jours suivant la date de votre commande initiale. Si vous souhaitez retourner vos articles, envoyez-nous un email avec votre numéro de commande.'
      },
      {
        question: 'Qu’est-ce que Phoenix et comment ça marche ?',
        answer:
          'Nous permettons le retour de tous les articles dans les 30 jours suivant la date de votre commande initiale.'
      },
      {
        question: 'Combien coûte Shopify ?',
        answer:
          'date de votre commande initiale. Si vous souhaitez retourner vos articles, envoyez-nous un email avec votre numéro de commande'
      }
    ]
  },
  {
    id: 'delivery-101',
    name: 'Livraison',
    icon: faTruckFast,
    description:
      'Répondez à toutes les questions concernant la livraison de vos produits et services, et maintenez vos clients informés.',
    topFaqs: [
      {
        question: 'Proposez-vous une option de suivi de livraison ?',
        answer:
          'Oui. Vous pouvez suivre votre commande et les détails de l’expédition via le code d’achat que nous envoyons, et connaître le statut actuel de votre achat.'
      },
      {
        question: 'Que se passe-t-il si je ne suis pas disponible pour recevoir ?',
        answer:
          'Notre équipe de livraison essaiera de vous joindre si vous n\'êtes pas disponible, et vous pourrez choisir de le récupérer dans nos points de collecte.'
      }
    ],
    faqs: [
      {
        question:
          'Quelles sont vos politiques concernant les produits manquants ou endommagés ?',
        answer:
          'Nous remplaçons ou remboursons les produits endommagés si notre personnel de livraison commet une erreur. Notez que tout dommage de votre côté ou du côté du vendeur est irréversible.'
      },
      {
        question: 'Proposez-vous une livraison le jour même ou une option de livraison express ?',
        answer:
          'Oui, vous pouvez choisir votre option de livraison parmi les options proposées, et vous bénéficierez du service en conséquence.'
      },
      {
        question: 'Quel est le coût de la livraison ?',
        answer:
          'Nous avons trois options de livraison différentes disponibles pour nos clients. Les coûts varient donc, et vous obtiendrez les détails sur info.phoenix.themewagon.'
      },
      {
        question: 'Quels sont vos délais de livraison ?',
        answer:
          'Notre équipe de livraison s’efforce de respecter les délais, mais ces derniers peuvent varier en fonction des conditions climatiques ou des retards imprévus. Pour une estimation précise, contactez info.phoenix.themewagon.'
      },
      {
        question: 'Proposez-vous une livraison internationale ?',
        answer:
          'Oui, nous expédions nos produits à l’étranger. Vous pouvez vérifier notre service de livraison et les destinations sur info.phoenix.themewagon.'
      }
    ]
  },
  {
    id: 'payment-101',
    name: 'Paiement',
    icon: faFileInvoiceDollar,
    description:
      'Répondez aux questions sur les modes de paiement acceptés, les factures, et plus encore.',
    topFaqs: [
      {
        question: 'Quels modes de paiement acceptez-vous ?',
        answer:
          'Nous acceptons les paiements par carte de crédit, PayPal et virement bancaire pour faciliter votre achat. Choisissez simplement l’option qui vous convient le mieux.'
      },
      {
        question: 'Offrez-vous des plans de paiement ou des options de financement ?',
        answer:
          'Nous offrons des plans de financement pour nos clients, basés sur une politique de crédit préapprouvée. Veuillez contacter notre équipe de support pour plus d’informations.'
      }
    ],
    faqs: [
      {
        question: 'Puis-je obtenir une facture pour ma commande ?',
        answer:
          'Oui. Vous recevrez une facture détaillée pour chaque commande, indiquant les détails de l’achat et les méthodes de paiement utilisées.'
      },
      {
        question: 'Puis-je modifier ma méthode de paiement après avoir passé ma commande ?',
        answer:
          'Non, une fois la commande passée, vous ne pourrez pas modifier la méthode de paiement. Assurez-vous donc de choisir le mode de paiement approprié au moment de la commande.'
      },
      {
        question: 'Acceptez-vous les paiements en espèces à la livraison ?',
        answer:
          'Non, nous n’acceptons pas les paiements en espèces à la livraison pour des raisons de sécurité. Veuillez utiliser l’une de nos autres options de paiement pour finaliser votre achat.'
      }
    ]
  },
  {
    id: 'refund-101',
    name: 'Remboursement',
    icon: faPeace,
    description:
      'Répondez aux questions sur nos politiques de retour et de remboursement, afin que nos clients se sentent en sécurité avec leur achat.',
    topFaqs: [
      {
        question: 'Quelle est votre politique de retour ?',
        answer:
          'Nous acceptons les retours dans les 30 jours suivant la date d’achat, sous réserve de certaines conditions. Veuillez consulter notre politique de retour pour plus de détails.'
      },
      {
        question: 'Comment puis-je retourner un produit ?',
        answer:
          'Vous pouvez retourner un produit en nous contactant directement pour obtenir une autorisation de retour. Assurez-vous de suivre les instructions fournies pour un traitement rapide de votre retour.'
      }
    ],
    faqs: [
      {
        question: 'Quand vais-je recevoir mon remboursement après le retour d’un produit ?',
        answer:
          'Une fois que votre produit retourné a été reçu et inspecté, nous traiterons votre remboursement dans les 5 à 7 jours ouvrables suivant la réception. Le remboursement sera crédité selon la méthode de paiement initiale.'
      },
      {
        question: 'Y a-t-il des frais de restockage pour les retours ?',
        answer:
          'Oui, nous appliquons des frais de restockage de 20 % sur les produits retournés. Assurez-vous de lire notre politique de retour pour comprendre tous les frais applicables.'
      }
    ]
  },
  {
    id: 'account-101',
    name: 'Compte',
    icon: faSliders,
    description:
      'Trouvez des réponses sur la gestion de votre compte, les paramètres, et plus encore.',
    topFaqs: [
      {
        question: 'Comment puis-je réinitialiser mon mot de passe ?',
        answer:
          'Vous pouvez réinitialiser votre mot de passe en sélectionnant l’option « Mot de passe oublié » sur la page de connexion et en suivant les instructions fournies.'
      },
      {
        question: 'Comment mettre à jour mes informations de compte ?',
        answer:
          'Connectez-vous à votre compte et accédez à la section « Paramètres ». Vous pourrez mettre à jour toutes les informations pertinentes à partir de là.'
      }
    ],
    faqs: [
      {
        question: 'Comment puis-je fermer mon compte ?',
        answer:
          'Pour fermer votre compte, veuillez contacter notre équipe de support client. Ils vous guideront à travers les étapes nécessaires pour fermer votre compte de manière sécurisée.'
      },
      {
        question: 'Que faire si j’ai des problèmes avec la connexion à mon compte ?',
        answer:
          'Si vous rencontrez des problèmes pour vous connecter à votre compte, assurez-vous que vos informations d’identification sont correctes. Si le problème persiste, contactez notre équipe de support pour obtenir de l’aide.'
      }
    ]
  },
  {
    id: 'privacy-101',
    name: 'Confidentialité',
    icon: faCircleNodes,
    description:
      'Répondez aux questions sur la sécurité des données et la confidentialité de nos clients.',
    topFaqs: [
      {
        question: 'Comment protégez-vous mes informations personnelles ?',
        answer:
          'Nous utilisons des mesures de sécurité avancées pour protéger vos informations personnelles contre toute utilisation non autorisée ou accès.'
      },
      {
        question: 'Partagez-vous mes informations personnelles avec des tiers ?',
        answer:
          'Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement explicite, sauf dans les cas prévus par la loi.'
      }
    ],
    faqs: [
      {
        question: 'Quelles sont vos politiques de cookies ?',
        answer:
          'Nos politiques de cookies visent à vous offrir une expérience en ligne optimisée en collectant des informations sur votre utilisation du site. Vous pouvez gérer vos préférences de cookies dans les paramètres de votre navigateur.'
      },
      {
        question: 'Comment puis-je accéder à mes données personnelles ?',
        answer:
          'Vous pouvez accéder à vos données personnelles en vous connectant à votre compte et en accédant à la section « Paramètres ». De là, vous pouvez voir et gérer vos informations personnelles.'
      }
    ]
  },
  {
    id: 'general-101',
    name: 'Général',
    icon: faBell,
    description:
      'Obtenez des réponses générales sur notre entreprise, nos services et plus encore.',
      category: 'popular',
    topFaqs: [
      {
        question: 'Quelle est votre politique de satisfaction client ?',
        answer:
          'Nous visons à garantir la satisfaction de nos clients à chaque étape de leur expérience d’achat. Si vous n’êtes pas satisfait de notre service, veuillez nous contacter pour que nous puissions résoudre le problème.'
      },
      {
        question: 'Comment puis-je vous contacter pour obtenir de l’aide ?',
        answer:
          'Vous pouvez nous contacter par email à support.phoenix.template ou par téléphone au (800) 555-1212 pour obtenir de l’aide immédiate de notre équipe de support.'
      }
    ],
    faqs: [
      {
        question: 'Comment puis-je m’abonner à vos newsletters et mises à jour ?',
        answer:
          'Vous pouvez vous abonner à nos newsletters et mises à jour en vous inscrivant sur notre site web. Entrez simplement votre adresse email dans le champ prévu à cet effet.'
      },
      {
        question: 'Comment puis-je soumettre une suggestion ou un commentaire ?',
        answer:
          'Nous accueillons vos suggestions et commentaires avec plaisir. Veuillez nous envoyer un email à info.phoenix.themewagon avec vos idées, et nous les examinerons avec attention.'
      }
    ]
  },
  {
    id: 'billing-101',
    name: 'Facturation',
    icon: faCalendarXmark,
    description:
      'Obtenez des réponses sur les questions liées à la facturation, les factures, et plus encore.',
    topFaqs: [
      {
        question: 'Où puis-je trouver mes factures ?',
        answer:
          'Vous pouvez trouver toutes vos factures dans la section « Historique des commandes » de votre compte. Si vous avez des questions spécifiques, contactez notre équipe de support.'
      },
      {
        question: 'Comment puis-je télécharger une copie de ma facture ?',
        answer:
          'Connectez-vous à votre compte et accédez à la section « Historique des commandes ». À côté de chaque commande, vous trouverez un lien pour télécharger une copie de votre facture.'
      }
    ],
    faqs: [
      {
        question: 'Puis-je obtenir une facture modifiée après ma commande ?',
        answer:
          'Oui, si vous avez besoin d’une facture modifiée pour une raison quelconque, contactez notre équipe de support client. Ils vous guideront à travers le processus de modification de votre facture.'
      },
      {
        question: 'Quand ma carte de crédit sera-t-elle débitée pour ma commande ?',
        answer:
          'Votre carte de crédit sera débitée au moment de la commande, une fois que vous aurez confirmé les détails de l’achat. Assurez-vous de vérifier les informations de paiement avant de finaliser la commande.'
      }
    ]
  }
];

