import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { faFileExport, faFileImport, faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import SearchBox from 'components/common/SearchBox';
import EditorUsersTable, { EditorUsersTableColumns } from 'components/tables/iper-x/editor/EditorUsersTable';
import { editorTicketsBreadcrumbItems } from 'data/breadcrumbItem';
import { users } from 'data/project-management/usersInEditorData';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';

const TicketsInEditor = () => {
  const table = useAdvanceTable({
    data: users,
    columns: EditorUsersTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: true,
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [user, setUser] = useState({
    username: '',
    assigne: '',
    startDate: '',
    endDate: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser(prevUser => ({
      ...prevUser,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logique de soumission du formulaire ici
    handleCloseModal();
  };

  const handleSearchInputChange = (e) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <PageBreadcrumb items={editorTicketsBreadcrumbItems} />
            <h2 className="mb-2 text-1100">Vos tickets</h2>
          </h2>
        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleShowModal}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un ticket
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <Card className="">
          <Card.Body>
            <Row className="align-items-center g-3">
              <Col sm="auto" className="flex-1">
                <EditorUsersTable />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </AdvanceTableProvider>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="username">
              <Form.Label>Nom du projet</Form.Label>
              <Form.Control type="text" name="username" value={user.username} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="assigne">
              <Form.Label>Assigné à</Form.Label>
              <Form.Control as="select" name="assigne" value={user.assigne} onChange={handleChange} required>
                <option value="">Sélectionnez un développeur</option>
                <option value="Alex">Alex</option>
                <option value="Florian">Florian</option>
                <option value="Justin">Justin</option>
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="startDate">
              <Form.Label>Date de début</Form.Label>
              <Form.Control type="date" name="startDate" value={user.startDate} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="endDate">
              <Form.Label>Date de fin</Form.Label>
              <Form.Control type="date" name="endDate" value={user.endDate} onChange={handleChange} required />
            </Form.Group>

            <Button variant="primary" type="submit" className='mt-4'>
              Ajouter
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TicketsInEditor;
