import {
  faArrowUpFromBracket,
  faBan,
  faCircleCheck,
  faCirclePlus,
  faClose,
  faDownload,
  faFileExport, faFileImport, faFilter,
  faGears,
  faPencil,
  faPlus,
  faRefresh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import React, { ChangeEvent, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { trunksTableData } from "../../../../data/iper-x/ipbx/trunks";
import TrunksTable, { trunksTableColumns } from "../../../../components/tables/ipbx/TrunksTable";
import LeadsFilterModal from "../../../../components/modals/LeadsFilterModal";
import CallQueuesTable, { callQueuesTableColumns } from "../../../../components/tables/ipbx/CallQueuesTable";
import { callQueuesTableData } from "../../../../data/iper-x/ipbx/callQueues";

const CallQueuesList = () => {
  const table = useAdvanceTable({
    data: callQueuesTableData,
    columns: callQueuesTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      {/*<PageBreadcrumb items={editorUserBreadcrumbItems} />*/}
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Files d'attente</span>
          </h2>


        </div>
        <div className="mb-4">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox className="w-300"
                         placeholder="Rechercher"
                         onChange={handleSearchInputChange}
              />
            </Col>
            <Col
              xs="auto"
              className="scrollbar overflow-hidden-y flex-grow-1"
            ></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileImport} className="fs-9 me-2" />
                Importer
              </Button>
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un trunck
              </Button>
              <Button
                variant="phoenix-secondary"
                className="px-3 ms-2"
                onClick={() => setOpenFilterModal(true)}
              >
                <FontAwesomeIcon
                  icon={faFilter}
                  transform="down-3"
                  className="text-primary"
                />
              </Button>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />
            </Col>
          </Row>
        </div>
        <div
          className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <CallQueuesTable />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default CallQueuesList;

