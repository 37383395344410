import PhoenixDocCard from 'components/base/PhoenixDocCard';
import DocPageHeader from 'components/docs/DocPageHeader';
import DocPagesLayout from 'layouts/old/DocPagesLayout';
import { Col, Row } from "react-bootstrap";
import React from "react";
import PhoenixLiveEditor from "../../../../components/docs/PhoenixLiveEditor";


const generalInfoForm = `
function GeneralInfo() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      {/* Trunk Details */}
      <h3>Détails du trunk</h3>
      <Row className="mb-3 gx-3">
        {/* Trunk Name */}
        <Form.Group as={Col} md="6" controlId="formTrunkName">
          <Form.Label>Nom du trunk</Form.Label>
          <Form.Control type="text" placeholder="Entrer un nom du trunk" />
        </Form.Group>

        {/* Server Hostname/IP */}
        <Form.Group as={Col} md="6" controlId="formServerHostname">
          <Form.Label>Nom d'hôte ou IP du serveur</Form.Label>
          <Form.Control type="text" placeholder="Nom d'hôte ou IP" />
        </Form.Group>

        {/* Server Port */}
        <Form.Group as={Col} md="6" controlId="formServerPort">
          <Form.Label>Port du serveur</Form.Label>
          <Form.Control type="text" placeholder="5060" defaultValue="5060" />
        </Form.Group>

        {/* Automatic Detection */}
        <Form.Group as={Col} md="6" controlId="formAutoDetection">
          <Form.Check type="checkbox" label="Détection automatique" />
        </Form.Group>

        {/* Outgoing Proxy */}
        <Form.Group as={Col} md="6" controlId="formOutgoingProxy">
          <Form.Label>Proxy sortant</Form.Label>
          <Form.Control type="text" placeholder="Proxy sortant" />
        </Form.Group>

        {/* Proxy Port */}
        <Form.Group as={Col} md="6" controlId="formProxyPort">
          <Form.Label>Port du proxy</Form.Label>
          <Form.Control type="text" placeholder="5060" defaultValue="5060" />
        </Form.Group>

        {/* Simultaneous Calls */}
        <Form.Group as={Col} md="6" controlId="formSimultaneousCalls">
          <Form.Label>Nombre d'appels simultanés</Form.Label>
          <Form.Control type="number" placeholder="Nombre d'appels" />
        </Form.Group>
      </Row>

      {/* Authentication */}
      <h3>Authentification</h3>
      <Row className="mb-3 gx-3">
        {/* Authentication Type */}
        <Form.Group as={Col} md="6" controlId="formAuthType">
          <Form.Label>Type d'authentification</Form.Label>
          <Form.Control as="select">
            <option>Basé sur enregistrement/compte</option>
            {/* Other authentication types */}
          </Form.Control>
        </Form.Group>

        {/* SIP User ID */}
        <Form.Group as={Col} md="6" controlId="formSipUserID">
          <Form.Label>ID d'authentification (SIP User ID)</Form.Label>
          <Form.Control type="text" placeholder="SIP User ID" defaultValue="FRENCHBURGERS63d393fce4b48" />
        </Form.Group>

        {/* Authentication Password */}
        <Form.Group as={Col} md="6" controlId="formAuthPassword">
          <Form.Label>Mot de passe d'authentification</Form.Label>
          <Form.Control type="password" placeholder="Mot de passe" />
        </Form.Group>

        {/* 3 Way Authentication Password */}
        <Form.Group as={Col} md="6" controlId="formAuthPassword3Way">
          <Form.Label>Mot de passe d'authentification 3 Way</Form.Label>
          <Form.Control type="password" placeholder="Mot de passe 3 Way" />
        </Form.Group>
      </Row>

      {/* Routing */}
      <h3>Router vers</h3>
      <Row className="mb-3 gx-3">
        {/* Main Number */}
        <Form.Group as={Col} md="6" controlId="formMainNumberRouting">
          <Form.Label>Numéro principal</Form.Label>
          <Form.Control type="text" placeholder="Numéro principal" defaultValue="0554073257" />
        </Form.Group>

        {/* Call Destination Office Hours */}
        <Form.Group as={Col} md="6" controlId="formCallDestinationOfficeHours">
          <Form.Label>Destination des appels pendant les heures de bureau</Form.Label>
          <Form.Control as="select">
            {/* Options for call destination during office hours */}
          </Form.Control>
        </Form.Group>

        {/* Call Destination Off Hours */}
        <Form.Group as={Col} md="6" controlId="formCallDestinationOffHours">
          <Form.Label>Destination des appels en dehors des heures de bureau</Form.Label>
          <Form.Control as="select">
            {/* Options for call destination outside office hours */}
          </Form.Control>
        </Form.Group>

        {/* Specific Trunk Schedules */}
        <Form.Group as={Col} md="6" controlId="formSpecificTrunkSchedules">
          <Form.Check type="checkbox" label="Définir les horaires spécifiques de ce trunk" />
        </Form.Group>

        {/* Holiday Message */}
        <Form.Group as={Col} md="6" controlId="formHolidayMessage">
          <Form.Check type="checkbox" label="Jouer un message de férié lorsqu'on est un jour férié défini en paramètres généraux" />
        </Form.Group>
      </Row>

      {/* Submit Button */}
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}
`;


const callerIDSettingsForm = `
function CallerIDSettings() {
  return (
    <Form>
      {/* Convert incoming caller ID */}
      <Form.Group className="mb-3" controlId="convertIncomingCallerID">
        <Form.Check 
          type="checkbox" 
          label="Convertir l’identifiant appelant entrant au format de numéro e164" 
        />
      </Form.Group>

      {/* Country Selection */}
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} controlId="countrySelect">
          <Form.Label>Pays</Form.Label>
          <Form.Control as="select">
            <option>Not specified</option>
            {/* Add other country options here */}
          </Form.Control>
        </Form.Group>
      </Row>

      {/* Default Outgoing Caller ID */}
      <h4>Identifiant appelant sortant par défaut</h4>
      <Form.Group className="mb-3" controlId="defaultOutgoingCallerID">
        <Form.Check 
          type="checkbox" 
          label="Configurer un numéro d'appelant par défaut" 
        />
      </Form.Group>

      {/* Reformatting Caller ID */}
      <h4>Reformater l’appelant entrant ou sortant</h4>
      <Form.Text className="text-muted">
        Reformate les numéros appelants sur appels entrants ou sortants par configuration de masques. Cliquer ici pour plus d'informations.
      </Form.Text>
      <Row className="mb-3 gx-3">
        <Form.Group as={Col} controlId="reformatIncoming">
          <Form.Check 
            type="checkbox" 
            label="Entrant uniquement" 
          />
        </Form.Group>
        <Form.Group as={Col} controlId="reformatOutgoing">
          <Form.Check 
            type="checkbox" 
            label="Sortant uniquement" 
          />
        </Form.Group>
      </Row>

      {/* Submit Button */}
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}
`;


const optionsForm = `
function Options() {
  return (
    <Form>
      {/* Call Options */}
      <h3>Options d'appel</h3>
      <Form.Group className="mb-3" controlId="allowIncomingCalls">
        <Form.Check type="checkbox" label="Autoriser les appels entrants" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="allowOutgoingCalls">
        <Form.Check type="checkbox" label="Autoriser les appels sortants" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="disableVideoCalls">
        <Form.Check type="checkbox" label="Désactiver les appels vidéo" />
      </Form.Group>

      <hr />

      {/* Advanced Options */}
      <h3>Avancé</h3>
      <Form.Group className="mb-3" controlId="deliverAudio">
        <Form.Check type="checkbox" label="Le PBX délivre l'audio" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="supportReInvite">
        <Form.Check type="checkbox" label="Support du Re-Invite" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="supportReplaces">
        <Form.Check type="checkbox" label="Support du Replaces" />
      </Form.Group>

      {/* Submit Button */}
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}
`;

const incomingSettingsForm = `
function IncomingSettings() {
  return (
    <Form>
      {/* Mappage des champs de nom/numéro d'appelant */}
      <h3>Mappage des champs de nom/numéro d'appelant</h3>
      <Form.Group className="mb-3" controlId="callerNumMapping">
        <Form.Label>Numéro de l'appelant (CallerNum)</Form.Label>
        <Form.Control as="select">
          <option>De : Partie Utilisateur</option>
          {/* Ajoutez d'autres options pour le mappage du numéro de l'appelant */}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3" controlId="callerNameMapping">
        <Form.Label>Nom de l'appelant (CallerName)</Form.Label>
        <Form.Control as="select">
          <option>De : Nom d'affichage</option>
          {/* Ajoutez d'autres options pour le mappage du nom de l'appelant */}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3" controlId="calledNumMapping">
        <Form.Label>Numéro appelé (CalledNum)</Form.Label>
        <Form.Control as="select">
          <option>À : Partie Utilisateur</option>
          {/* Ajoutez d'autres options pour le mappage du numéro appelé */}
        </Form.Control>
      </Form.Group>

      <hr />

      {/* Rechercher le SDA dans ces champs */}
      <h3>Rechercher le SDA dans ces champs</h3>
      <Form.Text className="text-muted mb-3">
        Configurez cette option uniquement si le trunk SIP est basé sur IP (peering), ou ne supporte pas la détection automatique des appels entrants. Si vous avez plusieurs trunks du même opérateur ou rencontrez des problèmes avec les appels entrants, essayez de tester cette option (activée/désactivée) pour déterminer quelle configuration fonctionne le mieux avec ce trunk SIP.
      </Form.Text>
      <Form.Group className="mb-3" controlId="searchDIDInRequestLineURI">
        <Form.Check 
          type="checkbox" 
          label="URI de ligne de requête : Partie Utilisateur" 
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="useBothIdentificationRules">
        <Form.Check 
          type="checkbox" 
          label="Utiliser à la fois les règles d'identification de la source des appels et le champ Numéro/Nom de l'appelant -> Numéro appelé"/>
      </Form.Group>
      
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}


`;


const outgoingSettingsForm = `
function OutgoingSettings() {
  return (
    <Form>
      <h3>Paramètres sortants</h3>
      <p>Assigner les champs d'en-têtes SIP aux variables d'appel Iper-x.</p>
      
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>URI de ligne de requête: Partie Utilisateur</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"Numéro appelé" qui a été composé (par défaut : À -> utilisateur)' />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>URI de ligne de requête: Partie Hôte</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"GWHostPort" hôte/port du passerelle/fournisseur' />
        </Col>
      </Form.Group>

      {/* Continuez avec tous les autres champs de manière similaire */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Contact : Partie Utilisateur</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"AuthID" authentification' />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Contact : Partie Hôte</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"ContactUri" généralement, contenu du champ Contact' />
        </Col>
      </Form.Group>
      
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>À : Nom d'affichage</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue="Numéro appelé" qui a été composé (par défaut : À -> nom d'affichage)"/>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>À : Partie Utilisateur</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"Numéro appelé" qui a été composé (par défaut : À -> utilisateur)' />
        </Col>
      </Form.Group>

      {/* Continuez à ajouter plus de champs en fonction de vos données... */}
      
      {/* Exemple pour un autre champ */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>De : Nom d'affichage</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='Laisser la valeur par défaut' />
        </Col>
      </Form.Group>
      
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>De : Partie Utilisateur</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"OriginatorCallerID" Le numéro de l'appelant original sera envoyé' />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>De : Partie Hôte</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"GWHostPort" hôte/port du passerelle/fournisseur' />
        </Col>
      </Form.Group>
      
      {/* Agent utilisateur : Chaîne de texte */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Agent utilisateur : Chaîne de texte</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='Laisser la valeur par défaut' />
        </Col>
      </Form.Group>

      {/* ID de la partie distante - Partie appelée : Nom d'affichage */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>ID de la partie distante - Partie appelée : Nom d'affichage</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='Laisser la valeur par défaut' />
        </Col>
      </Form.Group>
      
      {/* Identité prétendue-P : Partie Hôte */}
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm={4}>Identité prétendue-P : Partie Hôte</Form.Label>
        <Col sm={8}>
          <Form.Control type="text" defaultValue='"GWHostPort" hôte/port du passerelle/fournisseur' />
        </Col>
      </Form.Group>
      
      <Button type="submit">Enregistrer</Button>
    </Form>
  );
}

`;


const AddTrunkSIPForm = () => {
  return (
    <div>
      <DocPageHeader
        title="Ajouter un trunk-SIP"
      />

      <DocPagesLayout>



        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="General">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={generalInfoForm} />
        </PhoenixDocCard>


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="SDAs">
          </PhoenixDocCard.Header>
          {/*<PhoenixDocCard.Body code={generalInfoForm} />*/}
        </PhoenixDocCard>


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="ID d'appelant">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={callerIDSettingsForm} />
        </PhoenixDocCard>


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Options">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={optionsForm} />
        </PhoenixDocCard>


        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Paramètres entrants">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={incomingSettingsForm} />
        </PhoenixDocCard>

        <PhoenixDocCard className="mb-4" >
          <PhoenixDocCard.Header title="Paramètres sortants">
          </PhoenixDocCard.Header>
          <PhoenixDocCard.Body code={outgoingSettingsForm} />
        </PhoenixDocCard>


      </DocPagesLayout>
    </div>
  );
};

export default AddTrunkSIPForm;
