import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import PageBreadcrumb, {
  PageBreadcrumbItem
} from '../../../../../components/common/PageBreadcrumb';
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

export const voiceMessagingBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Paramètre',
    url: '/apps/ipbx/parametres'
  },
  {
    label: 'Systéme',
    url: '/apps/ipbx/parametres?selected=system'
  },
  {
    label: "Messagerie vocale",
    url: '/apps/ipbx/parametres/voice-messaging'
  }
];

const VoiceMessagingConfig = () => {
  return (
    <>
      <PageBreadcrumb items={voiceMessagingBreadcrumbItems} />
      <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
        <h2 className="mb-0">
          <span className="me-3">Messagerie vocale</span>
        </h2>
      </div>

      <Card className="">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">
              <h3 className="fw-bolder mb-2 line-clamp-1">
                Menu de messagerie vocale
              </h3>



              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Numéro d'extension du menu de messagerie vocale
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>


              <Form.Group controlId="formNum" className="mt-3 mb-3">
                <Form.Label>
                  Ne pas enregistrer les messages vocaux de moins de (secondes)
                </Form.Label>
                <FloatingLabel controlId="floatingInputAuthMax" label="Nombre">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>



            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <h3 className="fw-bolder mb-2 line-clamp-1">
                Messagerie vocale
              </h3>

              <span className="me-3 fw-bolder">Quota actuel et espace utilisé</span>


              <Form.Group controlId="formNum" className=" mb-2 d-flex align-items-center justify-content-between">

                <div className="w-50">
                  <Form.Group controlId="formNum1" className="mt-3 mb-3">
                    <Form.Check.Label className="fs-8 fw-normal me-2">
                      Quota de messagerie vocale, Go
                    </Form.Check.Label>
                    <FloatingLabel controlId="floatingInputAuthMax1" label="Nombre">
                      <Form.Control type="text" value="10" />
                    </FloatingLabel>
                  </Form.Group>
                </div>


                <div className="ps-2 w-50">
                  <Form.Group controlId="formNum2" className="mt-3 mb-3">
                    <Form.Check.Label className="fs-8 fw-normal me-2">
                      Espace utilisé
                    </Form.Check.Label>
                    <FloatingLabel controlId="floatingInputAuthMax2" label="Nombre">
                      <Form.Control type="text" value="10" />
                    </FloatingLabel>
                  </Form.Group>
                </div>

              </Form.Group>

              <Form.Group controlId="formNum" className="mt-2 mb-2 d-flex align-items-center">
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Supprimer tous les messages vocaux des utilisateurs du système
                </Form.Check.Label>
                <div className="me-2">

                  <Button variant="btn btn-white border border-1 px-2">
                    Tout supprimer
                  </Button>

                </div>

              </Form.Group>


              <span className="me-3 fw-bolder">Options de vidange</span>


              <Form.Group controlId="formNum" className="mt-2 mb-2 d-flex align-items-center">
                <Form.Check type="checkbox" id="sameAsShipping" className="4">
                  <Form.Check.Input type="checkbox" />
                  <Form.Check.Label className="fs-8 fw-normal">
                    <span className="me-3">
                      Supprimer automatiquement tous les messages vocaux plus vieux de :
                    </span>
                  </Form.Check.Label>
                </Form.Check>
                <div className="me-2">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="jours"
                  >
                    <Form.Control type="text" value="30" />
                  </FloatingLabel>
                </div>

              </Form.Group>

          <span className="me-3 fw-bolder">Email de notification</span>


              <Form.Group controlId="formNum" className="mt-3 mb-3 d-flex align-items-center">
                <Form.Check type="checkbox" id="sameAsShipping" className="4">
                  <Form.Check.Input type="checkbox" />
                  <Form.Check.Label className="fs-8 fw-normal">
                    <span className="me-3">
                      Envoyer un email lorsque la taille du dossier de messageries vocales atteint :
                    </span>
                  </Form.Check.Label>
                </Form.Check>
                <div className="me-2">
                  <FloatingLabel
                    controlId="floatingInputAuthMax"
                    label="% du quota"
                  >
                    <Form.Control type="text" value="75" />
                  </FloatingLabel>
                </div>

              </Form.Group>




            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mt-5 mb-5">
        <Card.Body>
          <Row className="align-items-center g-3">
            <Col sm="auto" className="flex-1">

              <Form.Check type="checkbox" id="sameAsShipping" className="4">
                <Form.Check.Input type="checkbox" />
                <Form.Check.Label className="fs-8 fw-normal">
                  <h3 className="fw-bolder mb-2 line-clamp-1">
                    Utiliser la transcription de messagerie vocale (Disponible en Professionnel et Entreprise)
                  </h3>
                </Form.Check.Label>
              </Form.Check>

              <span className="me-3 fw-bolder">
                IPER-X peut automatiquement transcrire un message vocal en texte et inclure cela dans l'email en utilisant le service Google Speech. Lisez ici pour savoir comment configurer un compte.
              </span>

              <Form.Group controlId="formNum1" className="mt-3 mb-3">
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Clé API Google Speech
                </Form.Check.Label>
                <FloatingLabel controlId="floatingInputAuthMax1" label="token">
                  <Form.Control type="text" value="10" />
                </FloatingLabel>
              </Form.Group>

              <Form.Group controlId="formNum1" className="mt-3 mb-3">
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Langue du message
                </Form.Check.Label>
                <FloatingLabel controlId="floatingSelect" label="Choisir la langue">
                  <Form.Select aria-label="Floating label select example">
                    <option>English (United Stades)</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>



              <Form.Group controlId="formNum1" className="mt-3 mb-3">
                <Form.Check.Label className="fs-8 fw-normal me-2">
                  Choisissez ce qui doit être transcrit (la première minute de l'enregistrement est transcrite) :
                </Form.Check.Label>
                <FloatingLabel controlId="floatingSelect" label="Choisir le mode">
                  <Form.Select aria-label="Floating label select example">
                    <option>Messages vocaux uniquement</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>


            </Col>
          </Row>
        </Card.Body>
      </Card>

    </>
  );
};

export default VoiceMessagingConfig;
