import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import { Member } from "data/iper-x/editor/editorRetailerMembersInfo";
import { CSSProperties } from "react";
import CircleProgress from "components/icons/CircleProgress";
import { getColor, getProgressColorVariant } from "helpers/utils";

export const EditorRetailerMemberColumn: ColumnDef<Member>[] = [
  {
    accessorKey: 'name',
    header: 'Compagnie',
    cell: ({ row: { original } }) => {
      const { name, avatar } = original;
      return (
        <Link
          to={`/portail-editeur/revendeur/${name}`}
          className=" d-flex align-items-center text-900 text-hover-1000"
        >
          <Avatar src={avatar} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: 'Email',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semi-bold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'mobile',
    header: 'Numéro de téléphone',
    cell: ({ row: { original } }) => {
      const { mobile } = original;
      return (
        <Link to={`tel:${mobile}`} className="fw-bold text-1100">
          {mobile}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'city',
    header: 'Ville',
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'revenue',
    header: "Chiffre d'affaire",
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '200px' },
        className: 'text-start'
      },
      cellProps: {
        className: 'text-start text-700'
      }
    }
  },
  {
    id: 'stage',
    accessorFn: ({ stage }) => stage.label,
    header: 'Status suivant',
    cell: ({ row: { original } }) => {
      const { label, value } = original.stage;
      return (
        <div className="d-flex align-items-center gap-3">
          <div
            style={{ '--phoenix-circle-progress-bar': value } as CSSProperties}
          >
            <CircleProgress color={getColor(getProgressColorVariant(value))} />
          </div>
          <h6 className="mb-0 text-900">{label}</h6>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { minWidth: 160 },
        className: 'pe-5 ps-4'
      },
      cellProps: {
        style: { paddingTop: 1, paddingBottom: 1 },
        className: 'white-space-nowrap fw-bold text-900 pe-5 overflow-hidden'
      }
    }
  },
  {
    accessorKey: 'joined',
    header: 'Rejoint',
    meta: {
      headerProps: {
        style: { width: '19%', minWidth: '200px' },
        className: 'text-center pe-0'
      },
      cellProps: {
        className: 'text-700 text-center'
      }
    }
  }
];

const EditorRetailerMembers = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default EditorRetailerMembers;
