import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import Badge from 'components/base/Badge';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from 'react-bootstrap';
import Unicon from 'components/base/Unicon';
import { UilDesktopAlt, UilRocket } from '@iconscout/react-unicons';
import FeatherIcon from 'feather-icons-react';

export interface RetailerLeadDataType {
  idLeads: number;
  name: string;
  ceoName: string;
  siret: string;
  address: string;
  mail: string;
  website: string;
  phone: string;
  numbersEmployees: number;
  annualIncome: number;
  nafCode: string;
  idSourcesLeads: number;
  idCompaniesClientsTypes: number;
  idCommonCities: number;
  devicesNumber: number;
  accessNumber: number;
  progressMin: number;
  progressMax: number;
  statusLabel: string;
  statusType: string;
  idUserAssignedRetailer: number;
}
const currentDate = new Date().toLocaleDateString();
export const retailerLeadsTableColumns: ColumnDef<RetailerLeadDataType>[] = [
  {
    accessorKey: 'name',
    header: 'Prospect',
    cell: ({ row: { original } }) => {
      return (
        <div className="d-flex flex-column">
          <Link
            to={`/portail-revendeur/lead/${original.idLeads}`}
            className="fs-8 fw-bold mb-1" // Ajoute une marge inférieure pour espacer les lignes
          >
            {original.name}
          </Link>
          <div className="d-flex flex-column">
            <p className="mb-0 text-1000 fw-semi-bold fs-9 me-2">
              PDG: {original.ceoName}
            </p>
            {/*<p className="mb-0 text-muted fs-9">Date: {currentDate}</p>*/}
          </div>
        </div>
      );
    },
    meta: {
      headerProps: {
        style: { width: "20%" },
        className: "ps-4 pe-5 border-end"
      },
      cellProps: {
        className: "white-space-nowrap ps-4 border-end fw-semi-bold text-1000"
      }
    }
  },
  {
    accessorKey: 'ceoName',
    header: () => (
      <div className="d-inline-flex flex-center">
        <div className="px-1 py-1 bg-warning-100 rounded me-2">
          <FeatherIcon
            icon="user"
            size={16}
            className="text-warning-600 dark__text-warning-300"
          />
        </div>
        <span>Revendeur/Contact</span>
      </div>
    ),
    cell: ({ row: { original } }) => (
      <div className="d-flex align-items-center">
        <Avatar src="" size="xl" className="me-3" />
        <div>
          <span>{original.ceoName}</span>
        </div>
      </div>
    ),
    meta: {
      headerProps: {
        style: { width: '20%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'devicesNumber',
    header: () => (
      <div className="d-inline-flex flex-center">
        <div className="px-1 py-1 bg-primary-100 rounded me-2">
          <Unicon
            icon={UilDesktopAlt}
            className="text-primary-600 dark__text-primary-300"
            size={16}
          />
        </div>
        <span>Nombre de poste</span>
      </div>
    ),
    cell: ({ row: { original } }) => original.devicesNumber,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    accessorKey: 'accessNumber',
    header: () => (
      <div className="d-inline-flex flex-center">
        <div className="px-1 py-1 bg-primary-100 rounded me-2">
          <Unicon
            icon={UilRocket}
            className="text-primary-600 dark__text-primary-300"
            size={16}
          />
        </div>
        <span>Nombre d’accès</span>
      </div>
    ),
    cell: ({ row: { original } }) => original.accessNumber,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      }
    }
  },
  {
    id: 'progress',
    header: 'Progress',
    cell: ({ row: { original } }) => (
      <>
        <p className="text-800 fs-10 mb-0">
          {original.progressMin} / {original.progressMax}
        </p>
        <ProgressBar
          now={(original.progressMin / original.progressMax) * 100}
          style={{ height: 3 }}
          variant="success"
        />
      </>
    ),
    meta: {
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      },
      headerProps: { style: { width: '5%' }, className: 'ps-4 pe-5 border-end' }
    }
  },

  {
    accessorKey: 'statusLabel',
    header: 'Status',
    cell: ({ row: { original } }) => (
      <Badge variant="phoenix" bg="success">
        {original.statusLabel}
      </Badge>
    ),
    meta: {
      cellProps: {
        className: 'white-space-nowrap ps-4 border-end fw-semi-bold text-1000'
      },
      headerProps: { style: { width: '5%' }, className: 'ps-4 pe-5 border-end' }
    }
  },

  {
    accessorKey: 'date',
    header: 'Date de création',
    cell: ({ row: { original } }) => {
      const defaultDate = new Date().toLocaleDateString(); // Date par défaut
      return defaultDate; //original.date ? original.date : defaultDate; // Utilise la date ou la date par défaut
    },
    meta: {
      headerProps: { style: { width: '15%' } },
      cellProps: { className: 'white-space-nowrap ps-4 text-700' }
    }
  },

  {
    id: 'leadDropdown',
    cell: () => (
      <RevealDropdownTrigger>
        <RevealDropdown>
          <ActionDropdownItems />
        </RevealDropdown>
      </RevealDropdownTrigger>
    ),
    meta: { cellProps: { className: 'text-end pe-0 ps-4' } }
  }
];

const RetailerLeadsTable = () => {
  return (
    <div className="border-top">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default RetailerLeadsTable;
