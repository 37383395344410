import { Col, Row } from 'react-bootstrap';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { stats } from 'data/crm/stats';
import AnalyticsStatsEditor from 'components/stats/AnalyticstatsEditor';
import LeadsInEditor from 'pages/apps/editor/LeadsInEditor';
import EditorRetailer from 'pages/EditorRetailer';
import EditorEcomTopRegionsMap from 'components/leaflet-maps/EditorEcomTopRegionsMap';
import { mapMarkerPoints } from 'data/mapMarkerPoints';
import EditorMap from 'components/modules/e-commerce/dashboard/EditorMap';
import EditorGoalAndNumber from 'components/modules/e-commerce/dashboard/EditorGoalAndNumber';
import { editorBreadcrumbItems } from 'data/breadcrumbItem';




const DashboardEditor = () => {
  return (
    <>
      <Row className="gy-3 mb-6 justify-content-between">
        <Col md={9} xs="auto">
          <PageBreadcrumb items={editorBreadcrumbItems} />
          <h2 className="mb-2 text-1100">Portail éditeur</h2>
        </Col >
        <Col md={12} xs="auto" >
          <div className="px-3 mb-5 card">
            <AnalyticsStatsEditor stats={stats} />
          </div>
        </Col>

        <Col md={12} xs="auto" className="p-3" >
          <div>
            <EditorGoalAndNumber />
          </div>
        </Col>
        <Col md={12} xs="auto" >
          <div>
            <  LeadsInEditor />
          </div>
        </Col>
        <Col md={12} xs="auto" className="p-3" >
          <div>
            <EditorRetailer />
          </div>
        </Col>
        <Row className="gx-5">
        <Col xs={12} xl={6}>
          <EditorMap />
        </Col>
        <Col xs={12} xl={6}>
          <div className="mx-n4 mx-lg-n6 ms-xl-0 h-100">
            <div className="h-100 w-100" style={{ minHeight: 300 }}>
              <EditorEcomTopRegionsMap data={mapMarkerPoints} />
            </div>
          </div>
        </Col>
      </Row>
      </Row>
    </>
  );
};

export default DashboardEditor;
