import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import { UsersInEditor } from "data/iper-x/ipbx/usersInEditor";

export const usersTableColumnsInEditor: ColumnDef<UsersInEditor>[] = [

  {
    id: "user",
    accessorFn: ({ user }) => user.name,
    header: "Nom d’utilisateur",
    cell: ({ row: { original } }) => {
      const { name, avatar} = original.user;
      return (
        <>
        <Link
          to={`/portail-editeur/utilisateurs/details/${name}`}
          className="d-flex align-items-center"
        >
          <Avatar src={avatar} size="m" className="me-3"/>
          <p className="mb-0 text-1100 fw-bold">{name}</p>
        </Link>    
          </>
      );
    },
    meta: {
      headerProps: {
        style: { width: "16%"},
      },
      cellProps: { className: "py-0 white-space-nowrap" }
    }
  },
  {
    accessorKey: "fonction",
    header: "Fonction",
    meta: {
      headerProps: { style: { width: "16%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "rôle",
    header: "rôle",
    meta: {
      headerProps: { style: { width: "16%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  
  {
    accessorKey: 'certification',
    header: 'Certification',
    cell: ({ row: { original } }) => {
      const { certification} = original.user;
      return(
        <img src={certification} style={{width:'16%'}
        }/>
      );
    },
    meta: {
      headerProps: {
        style: { width: "16%" },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6'
      }
    }
  },
  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "16%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "fixe",
    header: "Fixe",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
];

const UsersTableInEditor = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UsersTableInEditor;
