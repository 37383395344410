import team32 from 'assets/img/team/32.webp';
import team35 from 'assets/img/team/35.webp';
import team57 from 'assets/img/team/57.webp';
import team58 from 'assets/img/team/58.webp';
import team59 from 'assets/img/team/59.webp';
import team31 from 'assets/img/team/31.webp';
import team33 from 'assets/img/team/33.webp';
import team34 from 'assets/img/team/34.webp';
import team29 from 'assets/img/team/29.webp';
import team3 from 'assets/img/team/3.webp';
import team25 from 'assets/img/team/25.webp';
import { BadgeBg } from 'components/base/Badge';


export interface Order {
  orderId: number;
  total: number;
  customer: {
    avatar?: string;
    name: string;
    placeholder?: boolean;
  };
  payment_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };
  fulfilment_status: {
    label: string;
    type: BadgeBg;
    icon: string;
  };
  delivery_type: string;
  date: string;
}

export const editorOrdersTableData: Order[] = [
  {
    orderId: 2453,
    total: 87,
    customer: {
      avatar: team32,
      name: 'Carry Anna'
    },
    payment_status: {
      label: 'Payé',
      type: 'success',
      icon: 'check'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Paiement à la livraison',
    date: '12 déc., 12:56'
  },
  {
    orderId: 2452,
    total: 7264,
    customer: {
      name: 'Milind Mikuja'
    },
    fulfilment_status: {
      label: 'Prêt à être récupéré',
      type: 'info',
      icon: 'info'
    },
    payment_status: {
      label: 'Annulé',
      type: 'secondary',
      icon: 'x'
    },
    delivery_type: 'Livraison gratuite',
    date: '9 déc., 14:28'
  },
  {
    orderId: 2451,
    total: 375,
    customer: {
      avatar: team35,
      name: 'Stanly Drinkwater'
    },
    payment_status: {
      label: 'En attente',
      type: 'warning',
      icon: 'clock'
    },
    fulfilment_status: {
      label: 'Partiellement Livrée',
      type: 'warning',
      icon: 'info'
    },
    delivery_type: 'Ramassage local',
    date: '4 déc., 12:56'
  },
  {
    orderId: 2450,
    total: 657,
    customer: {
      avatar: team57,
      name: 'Josef Stravinsky'
    },
    payment_status: {
      label: 'Annulé',
      type: 'secondary',
      icon: 'x'
    },
    fulfilment_status: {
      label: 'Commande Annulée',
      type: 'secondary',
      icon: 'x'
    },
    delivery_type: 'Livraison standard',
    date: '1 déc., 04:07'
  },
  {
    orderId: 2449,
    total: 9562,
    customer: {
      avatar: team58,
      name: 'Igor Borvibson'
    },
    payment_status: {
      label: 'Échec',
      type: 'danger',
      icon: 'info'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Express',
    date: '28 nov., 19:28'
  },
  {
    orderId: 2448,
    total: 46,
    customer: {
      avatar: team59,
      name: 'Katerina Karenin'
    },
    payment_status: {
      label: 'Payé',
      type: 'success',
      icon: 'check'
    },
    fulfilment_status: {
      label: 'LIVRAISON RETARDÉE',
      type: 'danger',
      icon: 'info'
    },
    delivery_type: 'Livraison locale',
    date: '24 nov., 10:16'
  },
  {
    orderId: 2447,
    total: 953,
    customer: {
      name: 'Roy Anderson'
    },
    payment_status: {
      label: 'En attente',
      type: 'warning',
      icon: 'clock'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Paiement à la livraison',
    date: '18 nov., 17:43'
  },
  {
    orderId: 2446,
    total: 12,
    customer: {
      avatar: team31,
      name: 'Martina Scorcese'
    },
    payment_status: {
      label: 'En attente',
      type: 'warning',
      icon: 'clock'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Livraison standard',
    date: '18 nov., 02:09'
  },
  {
    orderId: 2445,
    total: 3927,
    customer: {
      avatar: team33,
      name: 'Luis Bunuel'
    },
    payment_status: {
      label: 'Payé',
      type: 'success',
      icon: 'check'
    },
    fulfilment_status: {
      label: 'Commande Annulée',
      type: 'secondary',
      icon: 'x'
    },
    delivery_type: 'Paiement à la livraison',
    date: '16 nov., 15:22'
  },
  {
    orderId: 2444,
    total: 5937,
    customer: {
      avatar: team34,
      name: 'Jean Renoir'
    },
    payment_status: {
      label: 'Payé',
      type: 'success',
      icon: 'check'
    },
    fulfilment_status: {
      label: 'Prêt à être récupéré',
      type: 'secondary',
      icon: 'info'
    },
    delivery_type: 'Ramassage local',
    date: '9 nov., 08:49'
  },
  {
    orderId: 2443,
    total: 124,
    customer: {
      avatar: team29,
      name: 'Ricky Antony'
    },
    payment_status: {
      label: 'Échec',
      type: 'danger',
      icon: 'x'
    },
    fulfilment_status: {
      label: 'Non Livrée',
      type: 'danger',
      icon: 'x'
    },
    delivery_type: 'Paiement à la livraison',
    date: '5 nov., 16:35'
  },
  {
    orderId: 2442,
    total: 542,
    customer: {
      avatar: team3,
      name: 'Emma Watson'
    },
    payment_status: {
      label: 'En attente',
      type: 'warning',
      icon: 'clock'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Livraison standard',
    date: '5 nov., 12:00'
  },
  {
    orderId: 2441,
    total: 1480,
    customer: {
      avatar: '/team/avatar.webp',
      name: 'Jennifer Schramm',
      placeholder: true
    },
    payment_status: {
      label: 'Payé',
      type: 'success',
      icon: 'check'
    },
    fulfilment_status: {
      label: 'Prêt à être récupéré',
      type: 'info',
      icon: 'info'
    },
    delivery_type: 'Livraison locale',
    date: '2 nov., 02:00'
  },
  {
    orderId: 2440,
    total: 80,
    customer: {
      avatar: team25,
      name: 'Michael Jenkins'
    },
    payment_status: {
      label: 'Annulé',
      type: 'secondary',
      icon: 'x'
    },
    fulfilment_status: {
      label: 'Non Livrée',
      type: 'danger',
      icon: 'x'
    },
    delivery_type: 'Livraison gratuite',
    date: '30 oct., 16:25'
  },
  {
    orderId: 2439,
    total: 999,
    customer: {
      avatar: team32,
      name: 'Raymond Mims'
    },
    payment_status: {
      label: 'En attente',
      type: 'warning',
      icon: 'clock'
    },
    fulfilment_status: {
      label: 'Commande Livrée',
      type: 'success',
      icon: 'check'
    },
    delivery_type: 'Paiement à la livraison',
    date: '28 oct., 15:00'
  }
];
