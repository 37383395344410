import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { CallQueues } from "../../../data/iper-x/ipbx/callQueues"; // Assurez-vous que ce type contient les bonnes propriétés

export const callQueuesTableColumns: ColumnDef<CallQueues>[] = [
  // Colonne pour Ext.
  {
    accessorKey: "extension",
    header: "Ext.",
    meta: {
      headerProps: { style: { width: "5%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Nom de la file
  {
    accessorKey: "queueName",
    header: "Nom de la file",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Stratégie de scutation
  {
    accessorKey: "scuttingStrategy",
    header: "Stratégie de scutation",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour délai dépassé
  {
    accessorKey: "timeout",
    header: "délai dépassé",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  // Colonne pour Langue de la file
  {
    accessorKey: "queueLanguage",
    header: "Langue de la file",
    meta: {
      headerProps: { style: { width: "10%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },

];

const CallQueuesTable = () => {
  return (
    <div>
      <AdvanceTable  tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default CallQueuesTable;
